// import { BASE_URL } from '@/api/url';
// import BaseFuncApi from '@/api/base/commonFunc-api';
// import Enum from '@/enum/enum';
import UserClient from '@/api/user/user-client';
const userClient = new UserClient();
export default {
    loginUser: async function (context, payload) {
        var result = await userClient.postAsync({
            url: `${userClient.url}/login`,
            data: payload
        }, true);
        if (result.data && result.status && result.status === "success") {
            context.commit('updateUserId', result.data);
            return result.data;
        }
        return result;
    },

    getByUserName: async function (context, payload) {
        var result = await userClient.getAsync({
            url: `${userClient.url}/get/${userClient.userId}`,
        }, true);
        if (result.data && result.status && result.status === "success") {
            context.commit('updateUserId', result.data);
            return result.data;
        }
        return result;
    },

    updateUser: async function (context, payload) {

        let payloadCopy = _.omit(payload, ['passWord']);

        console.log(payloadCopy);
        var result = await userClient.postAsync({
            url: `${userClient.url}/update`,
            data: payloadCopy
        }, true);
        if (result.status === "success") {
            return true;
        }
        return false;
    },
}