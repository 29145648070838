import Vue from 'vue';
import moment from 'moment';
import _enum from '@/enum/enum';

const formatDate = (date, format = "DD/MM/YYYY hh:mm") =>{
    if (!date) return null;
    return moment(date).format(format)
}


const formatDateTimeCustom = (date) => {
    const now = moment();
        const targetTime = moment(date);
        const duration = moment.duration(now.diff(targetTime));
      
        let result = '';
      
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
      
        if (days > 0) {
          result = `${days}d `;
          if (hours > 0 || minutes > 0) {
            result += `${hours}h `;
          }
          if (minutes > 0) {
            result += `${minutes}p `;
          }
        } else if (hours > 0) {
          result = `${hours}h `;
          if (minutes > 0) {
            result += `${minutes}p `;
          }
        } else if (minutes > 0) {
          result = `${minutes}p `;
          if (seconds > 0) {
            result += `${seconds}s `;
          }
        } else if (seconds > 0) {
          result = `${seconds}s `;
        }
      
        return result.trim();
}


const iconLogSystemStatus = (status) => {
    let icon = ''
    switch (status) {
        case _enum.LogSystemStatus.Connect:
            icon = 'icon-connect'
            break
        case _enum.LogSystemStatus.Disconnect:
            icon = 'icon-disconnect'
            break
        case _enum.LogSystemStatus.JobSuccess:
            icon = 'icon-success'
            break
        case _enum.LogSystemStatus.PauseError:
            icon = 'icon-error'
            break
    }
    return icon
}

const SocialNetWork = (type) => {
    let text = 'Chung'
    switch (type) {
        case _enum.SocialNetWork.Youtube:
            text = 'Youtube'
            break
        case _enum.SocialNetWork.Facebook:
            text = 'Facebook'
            break
        case _enum.SocialNetWork.Tiktok:
            text = 'Tiktok'
            break
    }
    return text
}

export default { formatDate, iconLogSystemStatus, SocialNetWork, formatDateTimeCustom }

