<template>
  <div class="task">
    <div class="flex justify-between items-center header-task">
      <div>Xem thiết bị ({{ ViewDevices.length }})</div>
    </div>
    <div class="manage-task">
      <div>
        <div class="items">
          <div
            class="item flex justify-between items-center"
            v-for="(item, index) in ViewDevices"
            :key="index">
            <div class="text-item">{{ item.deviceName||item.model }} </div>
            <div class="flex items-center">
              <div class="task-option flex items-center justify-center">
                <div class="icon-16 icon-option"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogSystem />
    </div>
  </div>
</template>
<script setup>
import LogSystem from '../logSystem/LogSystem.vue'
import _enum from '@/enum/enum'
import { ref } from 'vue'
import { mapGetters } from 'vuex';
import { ModuleDevice } from '@/store/module-const'

</script>
<script>
export default {
  computed: {
    ...mapGetters(ModuleDevice, ['ViewDevices'])
  }
}
</script>
<style lang="scss" scoped>
@import url('@/assets/contents/scss/views/task/taskHeader.scss');
</style>
