<template>
  <div id="app">
    <!-- <AppMobile v-if="screenType == 1" />
    <AppWeb v-else /> -->
    <AppWeb  />
  </div>
</template>

<script>
import _enum from '@/enum/enum'
import FullPage from "@/pages/FullPage.vue";
import { mapGetters, mapMutations } from "vuex";
import { ModuleUser } from "./store/module-const";
import AppWeb from '@/app/App.vue'
import AppMobile from '@/app/AppMobile.vue'
export default {
  name: "App",
  components: {
    FullPage,
    AppWeb,
    AppMobile
  },
  data() {
    return {
      screenType: this.$commonFunc.getScreenType()
    };
  },
  computed: {
    ...mapGetters(ModuleUser, ["UserId"]),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  async created() {
  },

  methods: {
    onResize() {
      this.screenType = this.$commonFunc.getScreenType()
    }
  },
};
</script>

<style scoped lang="scss">
@import url("@/assets/contents/scss/common/common.scss");

#app {
  width: 100%;
  height: 100vh;
}
</style>
