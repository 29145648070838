<template>
  <div class="the-content">
    <router-view></router-view>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import url("@/assets/contents/scss/views/setting/settingContent.scss");
.the-content {
  width: calc(100% - 318px);
}
</style>
