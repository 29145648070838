
import { notification, message } from 'ant-design-vue';

/**
 * 
 * @param {*} type success, info, warning, error
 * @param {*} title 
 * @param {*} description 
 * @param {*} duration 
 * @param {*} placement top, bottom, topLeft, topRight, bottomLeft, bottomRight, 
 */
const openNotificationWithIcon = (type = 'success', title = '', description = '', duration = 3, placement = 'topRight') => {
    notification[type]({
        message: title,
        description: description,
        duration: duration
    });
};


const messageNotification = (text = '', type = 'success', duration = 2, onClose = () => { }) => {
    switch (type) {
        case 'success':
            message.success(text, duration, onClose);
            break;
        case 'error':
            message.error(text, duration, onClose);
            break;
        case 'warning':
            message.warning(text, duration, onClose);
            break;
        case 'loading':
            message.loading(text, duration, onClose);
            break;
        case 'info':
            message.info(text, duration, onClose);
            break;
    }
}

export default {
    openNotificationWithIcon,
    messageNotification
}