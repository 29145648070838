
import _enum from "@/enum/enum"
export default {
    Proxies: [],
    Proxy : {},
    TotalInfo: {
        totalRecord: 30,
        pageSize: 20,
        pageIndex: 1
    },
    ProxyPage : {}
}