export default {
    SetupAccounts: [
        {
            id: "1",
            accountType: 'Youtube',
            fistName: 'Nguyễn Văn',
            lastName: 'Hiến',
            userName: "nvhien",
            password: "nvhien",
            Email: 'nvhien@gmail.com',
            deviceName: 'A03-01'
        },
        {
            id: "2",
            accountType: 'Facebook',
            fistName: 'Nguyễn Văn',
            lastName: 'Hiến',
            userName: "nvhien",
            password: "nvhien",
            Email: 'nvhien@gmail.com',
            deviceName: 'A03-01'
        },
        {
            id: "3",
            accountType: 'Youtube',
            fistName: 'Nguyễn Văn',
            lastName: 'Hiến',
            userName: "nvhien",
            password: "nvhien",
            Email: 'nvhien@gmail.com',
            deviceName: 'A03-01'
        },
        {
            id: "4",
            accountType: 'Youtube',
            fistName: 'Nguyễn Văn',
            lastName: 'Hiến',
            userName: "nvhien",
            password: "nvhien",
            Email: 'nvhien@gmail.com',
            deviceName: 'A03-01'
        },
        {
            id: "5",
            accountType: 'Youtube',
            fistName: 'Nguyễn Văn',
            lastName: 'Hiến',
            userName: "nvhien",
            password: "nvhien",
            Email: 'nvhien@gmail.com',
            deviceName: 'A03-01'
        },
    ]
}