import actions from './actions-job.js'
import mutations from './mutations-job.js'
import getters from './getters-job.js'
import state from './state-job-work.js'

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}