
import GroupDeviceClient from '@/api/groupDevice/group-device-client';
const groupClient = new GroupDeviceClient();
export default {
    getGroupDevices: async function (context, payload) {
        var res = await groupClient.getAsync({
            url: `${groupClient.url}/${groupClient.userId}`,
        }, true);

        if (res && res.data) {
            context.commit('updateGroupDevices', res.data);
            return res.data;
        }
        return res;
    },

    addGroupDevice: async function (context, payload) {
        
        const data ={...payload,userId: groupClient.userId};
        var res = await groupClient.postAsync({
            url: `${groupClient.url}/add`,
            data : data
        }, true);

        if (res && res.data) {
            context.commit('addGroupDevice', res.data);
            return res.data;
        }
        return res;
    },

    updateGroupDevice: async function (context, payload) {
        var res = await groupClient.postAsync({
            url: `${groupClient.url}/update/${payload.groupId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('updateGroupDevice', res.data);
            return res.data;
        }
        return res;
    },

    deleteGroupDevice: async function (context, payload) {
        var res = await groupClient.postAsync({
            url: `${groupClient.url}/delete/${payload.groupId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteGroupDevice', res.data);
            return res.data;
        }
        return res;
    },

    addDevice: async function (context, payload) {
        var res = await groupClient.postAsync({
            url: `${groupClient.url}/add-device/${payload.groupId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('updateGroupDevice', res.data);
            return res.data;
        }
        return res;
    },
    deleteDevice: async function (context, payload) {
        var res = await groupClient.postAsync({
            url: `${groupClient.url}/delete-device/${payload.groupId}/${payload.deviceId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('updateGroupDevice', res.data);
            return res.data;
        }
        return res;
    }
}