<template>
  <div>
    Home
  </div>
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style lang="scss" scoped></style>