<template>
  <div class="h-input">
    <div v-if="props.title" class="dq-mgb-5 text-title">
      {{ props.title }}
    </div>
    <textarea
      style="width: 100%"
      :style="{ height: props.height + 'px' }"
      class="input"
      :class="props.inputClass"
      type="text"
      v-bind="$attrs"
      @input="handleInput"
      :maxlength="props.maxLength"
    ></textarea>
  </div>
</template>

<script setup>
import { computed } from "vue";
const emit = defineEmits(["input"]);
const props = defineProps({
  title: {
    default: "",
    typeof: String,
  },
  height: {
    default: 100,
    typeof: Number,
  },
  inputClass: {
    default: "",
    typeof: String,
  },
  maxLength: {
    default: 500,
    typeof: Number,
  },
});

const handleInput = (event) => {
  emit("input", event.target.value);
};
</script>
<style lang="scss" scoped>
.h-input {
  box-sizing: border-box;
}
.input {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding-left: 10px;
  outline: none !important;
}
.text-title {
  font-weight: bold;
}
</style>
