<template>
  <div>
    <div class="icon-left" :class="icon" v-if="icon && postion == 'left'"></div>
    <button @click="onEvent('click')" @blur="onEvent('blur')" @mousedown="onEvent('mousedown')"
      @mouseover="onEvent('mouseover')" @mouseleave="onEvent('mouseleave')"
      :class="[classButton, classHover, classActive, type, disabled ? 'disabled-btn' : '', `btn-${props.type}`, props.border ? '' : 'border-none']"
      :style="{
        minHeight: height + 'px',
        minWidth: width + 'px'
      }" :disabled="disabled" class="btn-default w-100" v-bind="$attrs">
      {{ props.title }}
    </button>
    <div class="icon-right" :class="icon" v-if="icon && postion == 'right'"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    default: 'ghost', // Các loại type : primary | ghost | dashed | link | text | default
    typeof: String
  },
  title: {
    default: '',
    typeof: String
  },
  disabled: {
    default: false,
    typeof: Boolean
  },
  icon: {
    default: false,
    typeof: String
  },
  classButton: {
    default: '',
    typeof: String
  },
  classActive: {
    default: '',
    typeof: String
  },
  classHover: {
    default: '',
    typeof: String
  },
  type: {
    default: '',
    typeof: String
  },
  height: {
    default: 36,
    typeof: Number
  },
  width: {
    default: 100,
    typeof: Number
  },
  postion: {
    default: 'left',
    typeof: String
  },
  icon: {
    default: '',
    typeof: String
  },
  border: {
    default: true,
    typeof: Boolean
  }
})

const emit = defineEmits(['click', 'blur', 'mousedown', 'mouseover', 'mouseleave'])

const onEvent = (event) => {
  switch (event) {
    case 'click':
      emit('click')
      break
    case 'blur':
      emit('blur')
      break
    case 'mousedown':
      emit('mousedown')
      break
    case 'mouseover':
      emit('mouseover')
      break
    case 'mouseleave':
      emit('mouseleave')
      break
  }
}
</script>

<style lang="scss" scoped>
.btn-default {
  height: 36px;
  padding: 5px 16px;
  min-width: 100px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  background: #fff;
  cursor: pointer;

  &:hover {
    color: #4096ff;
    border-color: #4096ff;
  }

  &:active {
    color: #0958d9;
    border-color: #0958d9;
  }
  // :hover {
  //   color: #4096ff;
  //   border-color: #4096ff;
  // }

  // :active {
  //   color: #0958d9;
  //   border-color: #0958d9;
  // }
}

.btn-primary {
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: white;
  background: #1677ff;
  &:hover {
    color: #fff !important;
    background-color: #4096ff !important;
  }

  &:active {
    color: #fff !important;
    background-color: #0958d9 !important;
  }
}


.border-none {
  border: none;
}
</style>
