<template>
  <div v-if="isShow">
    <h-dialog
      :title="title"
      :footerDefault="footerDefault"
      :isShow="isShow"
      @okDialog="okDialog"
      @cancelDialog="cancelDialog">
      <template #content>
        <div>
          <h-combobox
            :data="isMiningSetup ? JobsCoin : Jobs"
            :display="'jobName'"
            :keyValue="'jobId'"
            @change="onChangeCoin"></h-combobox>
        </div>
      </template>
    </h-dialog>
  </div>
  <div v-else></div>
</template>

<script setup>
import _enum from '@/enum/enum'
import { ModuleCoin, ModuleJob } from '@/store/module-const'
import { mapGetters, mapActions } from 'vuex'
import wsClient from '@/wsClient'
const emit = defineEmits(['cancelDialog', 'okDialog'])
</script>
<script>
export default {
  props: {
    isShow: {
      default: false,
      typeof: Boolean
    },
    isMiningSetup: {
      default: false,
      typeof: Boolean
    },
    title: {
      default: '',
      typeof: String
    },
    devices: {
      default: [],
      typeof: Array
    }
  },
  data() {
    return {
      footerDefault: {
        cancelText: 'Hủy bỏ',
        okText: 'Đồng ý',
        cancelStyle: {},
        okStyle: {}
      },
      currentItem: {},
      dropdownData: []
    }
  },

  watch: {
  },
  async created() {
    await this.getData()
  },
  computed: {
    ...mapGetters(ModuleJob, ['JobsCoin', 'Jobs'])
  },
  methods: {
    ...mapActions(ModuleJob, ['getCoinJobs', 'getJobs']),
    async getData() {
      if (this.isMiningSetup) {
        await this.getCoinJobs({ type: 0 })
      } else {
        await this.getJobs()

      }
    },
    getCurrentData(){
      return  this.isMiningSetup ? this.JobsCoin : this.Jobs
    },
    //Sự kiện khi thay đổi giá trị combo
    onChangeCoin(value) {
      this.currentItem = this.getCurrentData().find((item) => item.jobId == value)
    },
    cancelDialog() {
      this.$emit('cancelDialog')
    },
    okDialog() {
      this.$emit('okDialog', this.currentItem)
    }
  }
}
</script>

<style lang="scss" scoped></style>
