export default {
    updateTaskScripts(state, payload) {
        state.TaskScripts = [...payload];
    },

    addTaskScript(state, payload) {
        if (!state.TaskScripts) { state.TaskScripts = [] }
        state.TaskScripts.push(payload);
    },

    deleteTaskScript(state, payload) {
        if (!state.TaskScripts) { state.TaskScripts = [] }
        state.TaskScripts = state.TaskScripts.filter(x => x.scriptId != payload.scriptId);
    },

    deleteTaskScripts(state, payload) {
        if (!state.TaskScripts) { state.TaskScripts = [] }
        state.TaskScripts = state.TaskScripts.filter(x => x.scriptId != payload.scriptId);
    },

    updateTaskScript(state, payload) {
        if (!state.TaskScripts) { state.TaskScripts = [] }
        let index = state.TaskScripts.findIndex(x => x.groupId == payload.id);
        if (index >= 0) {
            state.TaskScripts[index] = payload;
        }
    },

    updateTaskScriptActive(state, payload) {
        state.TaskScript = { ...payload };
    },
}