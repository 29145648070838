<template>
  <div class="setting">
    <div class="flex justify-between items-center header-setting">
      <div>Thiết lập</div>
    </div>
    <div class="manage-setting">
      <div>
        <div class="items">
          <div
            class="item"
            v-for="(menu, index) in settingMenus"
            :key="index"
            @click="selectOption(menu, index)"
            :class="menu.isActive || $route.path == menu.router ? 'item-active' : ''"
          >
            {{ menu.name }}
          </div>
        </div>
      </div>
      <LogSystem />
    </div>
  </div>
</template>
<script setup>
import LogSystem from "../logSystem/LogSystem.vue";
import _enum from "@/enum/enum";
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const settingMenus = ref([
  {
    action: _enum.ActionSetting.SetupMining,
    name: "Thiết lập đào coin",
    router: "/setup-mining-coin",
  },
  // {
  //   action: _enum.ActionSetting.ScriptRun,
  //   name: "Kịch bản chạy",
  //   router: "/setup-script",
  // },
  // {
  //   action: _enum.ActionSetting.SetupWork,
  //   name: "Thiết lập công việc",
  //   router: "/setup-work",
  // },
  // {
  //   action: _enum.ActionSetting.Account,
  //   name: "Tài khoản",
  //   router: "/setup-account",
  // },
  // {
  //   action: _enum.ActionSetting.Proxy,
  //   name: "Proxy",
  //   router: "/proxy",
  // },
  {
    action: _enum.ActionSetting.CommonOption,
    name: "Tùy chọn chung",
    router: "/common-option",
  },
]);

const selectOption = (menu, index) => {
  if (!menu || !menu.router) return;
  settingMenus.value.forEach((element) => {
    element.isActive = false;
  });
  settingMenus.value[index].isActive = true;
  router.push(menu.router);
};
</script>

<style lang="scss" scoped>
@import url("@/assets/contents/scss/views/setting/settingHeader.scss");
</style>
