<template>
  <div v-if="isShow">
    <h-dialog :title="title" :footerDefault="footerDefault" :isShow="isShow" @okDialog="okDialog"
      @cancelDialog="cancelDialog" :width="800">
      <template #content>
        <div class="flex items-center">
          <div v-for="(tab, index) in tabs" :key="index">
            <div class="text-job-type" :class="tabActive == index ? 'text-job-type-active' : ''"
              @click="changeTypeJob(tab, index)">
              {{ tab.name }}</div>
          </div>
        </div>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Tên</div>
          <a-input v-model:value="currentItem.jobName" />
        </div>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Diễn giải</div>
          <a-textarea v-model:value="currentItem.description" :rows="8" />
        </div>
      </template>
    </h-dialog>
  </div>
  <div v-else></div>
</template>

<script setup>
import _enum from '@/enum/enum'
import { ModuleJob, ModuleProxy, ModuleTaskScript, ModuleUser } from '@/store/module-const'
import { mapGetters, mapActions } from 'vuex'
import commonFunc from '@/common/commonFunc'
</script>

<script>
export default {
  props: {
    isShow: {
      default: false,
      typeof: Boolean
    },
    title: {
      default: '',
      typeof: String
    },
    currentItem: {
      default: {
        type: _enum.SocialNetWork.Youtube
      },
      typeof: Object
    },
    mode: {
      default: 1,
      typeof: Number
    },
  },
  computed: {
    ...mapGetters(ModuleUser, ['UserId']),
  },
  data() {
    return {
      footerDefault: {
        cancelText: 'Hủy bỏ',
        okText: 'Lưu',
        cancelStyle: {},
        okStyle: {}
      },
      currentItem: {
        type: _enum.ProxyType.Turn
      },
      tabs: [],
      tabActive: 0
    }
  },
  created() {
    this.initDataStatic();
  },
  methods: {
    ...mapActions(ModuleJob, [
      'updateJob',
      'createJob'
    ]),
    initDataStatic() {
      this.tabs = [
        {
          name: 'Youtube',
          type: _enum.SocialNetWork.Youtube
        },
        {
          name: 'Tiktok',
          type: _enum.SocialNetWork.Tiktok
        },
        {
          name: 'Facebook',
          type: _enum.SocialNetWork.Facebook
        }
      ]
      this.columns = [
        {
          title: "Loại tài khoản",
          dataIndex: "accountType",
          sorter: (a, b) => a.accountType - b.accountType,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Họ và đệm",
          dataIndex: "firstName",
          sorter: (a, b) => a.firstName - b.firstName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Tên",
          dataIndex: "lastName",
          sorter: (a, b) => a.lastName - b.lastName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Username",
          dataIndex: "userName",
          sorter: (a, b) => a.userName - b.userName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Mật khẩu",
          dataIndex: "password",
          sorter: (a, b) => a.password - b.password,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Thiết bị đăng nhập",
          dataIndex: "deviceName",
          sorter: (a, b) => a.deviceName - b.deviceName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Chức năng",
          dataIndex: "action",
        },
      ]
    },

    cancelDialog() {
      this.$emit('cancelDialog');
    },
    async okDialog() {
      if (this.mode == _enum.Mode.Edit) {
        await this.updateJob(this.currentItem);
        this.$emit('okDialog', this.currentItem);
        return;
      }
      let payload = {
        ...this.currentItem,
        userId: this.UserId,
        jobId: commonFunc.uuidv4()
      }
      await this.createJob(payload);
      this.$emit('okDialog', payload);
    },
    onChangeApi(value) {
      this.currentItem.apiId = value;
    },
    changeTypeJob(tab, index) {
      this.tabActive = index;
      this.currentItem.type = tab.type;
    }
  }
}
</script>
<style lang="scss" scoped>
.text-job-type {
  display: flex;
  padding: 5px 16px;
  align-items: center;
  gap: 10px;
  background: var(--fill-color-blank, #FFF);
  border: 1px solid #DCDFE6;
  color: var(--text-color-regular, #606266);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.01px;
  cursor: pointer;
}

.text-job-type-active {
  display: flex;
  padding: 5px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 4px 0px 0px 4px;
  background: var(--color-primary, #409EFF);
  color: var(--color-white, var(--white, #FFF));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.01px;
  cursor: pointer;
}
</style>