<template>
  <div class="common-layout h-full">
    <el-container class="layout-container h-full">
      <el-header class="el-header">
         <slot name="header"></slot>
      </el-header>
      <el-main class="el-main">
         <slot name="content"></slot>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.layout-container .el-header {
  position: relative;
  padding-inline: 0px;
  height: 60px;
}
.layout-container .el-aside {
}
.layout-container .el-menu {
  border-right: none;
}
.layout-container .el-main {
  padding: 0;
  height: calc(100% - 60px);
  overflow: auto;
}
.layout-container .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
</style>