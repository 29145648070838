import moment from 'moment'
import commonFunc from './common/commonFunc';
import Enum from '@/enum/enum';
import _ from 'lodash';

import filter from './filters/filter';
import { store } from './store/store';
import messageNotification from '@/utils/messageNotification/messageNotification'

export const installPlugin = (app) => {
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.$commonFunc = commonFunc;
    app.config.globalProperties.$enum = Enum;
    app.config.globalProperties.$lodash = _;

    app.config.globalProperties.$filters = {
        ...filter
    };
    app.config.globalProperties.$stores = store;
    app.config.globalProperties.$messageNotification = messageNotification;
}

export const pluginGlobal = {
    $moment: moment,
    $commonFunc: commonFunc,
    $enum: Enum,
    $lodash: _,
    $filters: {
        ...filter
    },
    $stores: store,
    $messageNotification: messageNotification
}
