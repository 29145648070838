<template>
  <div v-if="isShow">
    <h-dialog :title="title" :footerDefault="footerDefault" :isShow="isShow" @okDialog="okDialog"
      @cancelDialog="cancelDialog" :width="800">
      <template #content>
        <div class="flex items-center">
          <div class="text-row">Tên thiết bị</div>
          <a-input v-model:value="currentItem.deviceName" placeholder="Tên thiết bị" :disabled="disabledView" />
        </div>
        <div class="flex items-center mt-2.5">
          <div class="text-row">Loại coin đào</div>
          <h-combobox :data="JobsCoin" :display="'jobName'" :keyValue="'jobId'" @change="onChangeCoin"
            :value="currentItem.jobId" :disabled="disabledView"></h-combobox>
        </div>
        <div class="flex items-center mt-2.5">
          <div class="text-row">Trạng thái chạy thiết bị</div>
          <h-checkbox v-model:value="currentItem.isRunningDevice" :disabled="disabledView"/>
        </div>
        
        <div>
        </div>
      </template>
    </h-dialog>
  </div>
  <div v-else></div>
</template>

<script setup>
import _enum from '@/enum/enum'
import { ModuleJob } from '@/store/module-const'
import { mapGetters, mapActions } from 'vuex'
</script>

<script>
export default {
  props: {
    isShow: {
      default: false,
      typeof: Boolean
    },
    title: {
      default: '',
      typeof: String
    },
    device: {
      default: {},
      typeof: Object
    },
    mode: {
      default: 1,
      typeof: Number
    }
  },
  data() {
    return {
      columns: [
        {
          title: 'Tên',
          dataIndex: 'deviceName',
          width: 200
        },
        {
          title: 'Kết quả',
          dataIndex: 'template'
        }
      ],
      footerDefault: {
        cancelText: 'Hủy bỏ',
        okText: 'Lưu',
        cancelStyle: {},
        okStyle: {}
      },
      currentItem: {
        deviceStr: '',
        deviceName: '',
        isRunningDevice :false
      },
    }
  },
  computed: {
    ...mapGetters(ModuleJob, ['JobsCoin']),
    disabledView() {
      return this.mode == _enum.Mode.View;
    }
  },

  watch: {
    isShow(newVal, oldVal) {
      if (newVal) {
        if (this.JobsCoin && this.JobsCoin.length > 0) {
          this.currentItem.jobId = this.JobsCoin[0].jobId;
        }
        this.currentItem.model = this.device.model;
        //Device status online
        this.currentItem.isRunningDevice = this.device.status == 1;
      } else {
        this.resetData();
      }
    }
  },
  mounted() {

  },

  async created() {
    await this.getData();
  },
  methods: {
    ...mapActions(ModuleJob, ['getCoinJobs']),
    async getData() {
      await this.getCoinJobs({ type: 0 })
    },
    cancelDialog() {
      this.$emit('cancelDialog')
    },
    okDialog() {
      this.currentItem.status = this.currentItem.isRunningDevice ? 1: 0;
delete this.currentItem.isRunningDevice;
      this.$emit('okDialog', {...this.device, ...this.currentItem})
    },
    //Sự kiện khi thay đổi giá trị combo
    onChangeCoin(value) {
      if (!this.JobsCoin) return;
      this.currentItem = { ...this.currentItem, ...this.JobsCoin.find((item) => item.jobId == value) };
    },
    resetData() {
      this.currentItem = {
        device: {},
        model: '',
        isRunningDevice : false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-devies {
  min-height: 200px;
  max-height: 450px;
  overflow: auto;
}

.text-row {
  display: flex;
  width: 200px;
  padding: 5px 0px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}
</style>