<template>
  <div class="" style="height: calc(100% - 100px)">
    <div class="flex items-center justify-between mt-4 pl-4 pr-4">
      <div class="title-setting">Thiết lập proxy</div>
      <div>
        <h-button :title="' Nhập nhanh Proxy'" :type="'primary'" @click="onClickAddProxy"></h-button>
      </div>
    </div>

    <div class="flex items-center justify-between mt-4 pr-5 pl-2">
      <div class="w-60">
        <a-input v-show="!selected || selected.length <= 0" v-model:value="params.search" placeholder="nhập tên để tìm"
          @keydown="filterData" />
        <div v-show="selected && selected.length > 0">
          <div class="icon-delete-style" @click="onClickDeleteSelected">
            <div class="icon-24 icon-delete-layout"></div>
          </div>
        </div>
      </div>
      <div class="icon-24 icon-refresh" @click="onClickRefresh"></div>
    </div>
    <div class="mt-3 grid-content overflow-auto">
      <h-grid :columns="columns" :data="Proxies" :checkbox="true" :keyField="'proxyId'" :pagination="true"
        :dataPagination="TotalInfo" @changePagination="changePagination" @onSelectedAll="onSelectedAll"
        @onSelected="onSelected">
        <template #widget="slotProps">
          <ProxyWidget @widgetEvent="widgetEvent" :data="slotProps.data" />
        </template>
      </h-grid>
    </div>
    <ProxyDetail @okDialog="okDialogProxyDetail" @cancelDialog="cancelDialogProxyDetail" :isShow="isShowDetail"
      :title="`Nhập nhanh proxy`" :currentItem="proxy" :mode="mode" />
  </div>
</template>

<script setup>
import { ModuleProxy } from "@/store/module-const";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import ProxyWidget from "./ProxyWidget.vue";
import ProxyDetail from "./ProxyDetail.vue";
import _enum from "@/enum/enum";
import messageNotification from "@/utils/messageNotification/messageNotification";
</script>

<script>
export default {
  props: {},
  computed: {
    ...mapGetters(ModuleProxy, ["Proxies", "TotalInfo"]),
  },
  data() {
    return {
      columns: [],
      selected: [],
      searchText: "",
      isShowDetail: false,
      mode: _enum.Mode.Add,
      proxy: { status: _enum.ProxyType.Turn },
      params: {
        pageIndex: 1,
        pageSize: 20,
        totalRecord: 0,
        search: ''
      },
    };
  },
  async created() {
    this.initDataStatic();
    await this.getData();
  },
  methods: {
    ...mapActions(ModuleProxy, [
      "getProxyPaging",
      "updateProxy",
      "createProxy",
      "deleteProxy",
      "deleteProxies",
    ]),
    initDataStatic() {
      this.tabs = [
        {
          title: "Youtube",
          type: _enum.SocialNetWork.Youtube,
        },
        {
          title: "Tiktok",
          type: _enum.SocialNetWork.Tiktok,
        },
        {
          title: "Facebook",
          type: _enum.SocialNetWork.Facebook,
        },
      ];
      this.columns = [
        {
          title: "Loại proxy",
          dataIndex: "type",
          sorter: (a, b) => a.type - b.type,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Thông tin",
          dataIndex: "contentView",
          sorter: (a, b) => a.contentView - b.contentView,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Username",
          dataIndex: "username",
          sorter: (a, b) => a.username - b.username,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Mật khẩu",
          dataIndex: "pass",
          sorter: (a, b) => a.pass - b.pass,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "API xoay",
          dataIndex: "api",
        },
        {
          title: "Thiết bị đang sử dụng",
          dataIndex: "deviceId",
          sorter: (a, b) => a.deviceId - b.deviceId,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Chức năng",
          dataIndex: "action",
        },
      ];
    },

    async getData() {
      const me = this;
      let payload = { ...me.params };
      await this.getProxyPaging(payload);
    },

    async changePagination(params) {
      const me = this;
      me.params = { ...me.params, ...params };
      me.getData();
    },

    onSelected(params) {
      this.selected = [...params.selectedRows];
    },

    onSelectedAll(params) {
      this.selected = [...params.selectedRows];
    },

    async widgetEvent(payload) {
      if (!payload || !payload.data || !payload.event) return;
      this.proxy = _.cloneDeep(payload.data);
      switch (payload.event) {
        case _enum.Event.Edit:
          this.isShowDetail = true;
          this.mode = _enum.Mode.Edit;
          break;
        case _enum.Event.Delele:
          await this.deleteProxyAsync({ proxyId: this.proxy ? this.proxy.proxyId : "" });
          break;
      }
    },
    deleteProxyAsync: _.debounce(async function (proxy) {
      let res = await this.deleteProxy(proxy);
      if (res) {
        await this.getData();
        messageNotification.messageNotification("Xóa thành công");
      } else {
        messageNotification.messageNotification("Xóa thất bại");
      }
    }, 300),

    filterData: _.debounce(async function () {
      await this.getData()
    }, 200),

    async onClickRefresh() {
      await this.getData();
    },

    onClickAddProxy() {
      this.isShowDetail = true;
    },

    async okDialogProxyDetail() {
      await this.getData();
      if (this.mode == _enum.Mode.Edit) {
        messageNotification.messageNotification("Sửa thành công");
      } else {
        messageNotification.messageNotification("Thêm thành công");
      }
      this.resetData();
    },

    cancelDialogProxyDetail() {
      this.resetData();
    },
    resetData() {
      this.isShowDetail = false;
      this.mode = _enum.Mode.Add;
      this.proxy = {
        status: _enum.ProxyType.Turn,
      };
    },

    onClickDeleteSelected: _.debounce(async function () {
      let proxyIds = this.selected.map((x) => x.proxyId);
      let res = await this.deleteProxies({ proxyIds: proxyIds });
      if (res) {
        await this.getData();
        messageNotification.messageNotification("Xóa thành công");
        this.selected = [];
      } else {
        messageNotification.messageNotification("Xóa thất bại");
      }
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.grid-content {
  height: calc(100% - 60px);
  width: 100%;
  overflow: auto;
}

.title-setting {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
