import actions from './actions-log.js'
import mutations from './mutations-log.js'
import getters from './getters-log.js'
import state from './state-log.js'

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}