import { ElMessage, ElLoading } from 'element-plus'
import moment from 'moment';
import shortid from 'shortid';
import _enum from '../../../lib/configs/enum';
export default {
    uuidv4() {
        return shortid.generate();
        // return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        // );
    },
    SuccessMess(mess) {
        ElMessage({
            message: mess,
            type: 'success',
        })
    },
    ErrorMess(mess) {
        ElMessage({
            message: mess,
            type: 'error',
        })
    },
    formatDateTime(dateTime) {
        return moment(dateTime).utcOffset(7).format('DD/MM/YY HH:mm:ss');
    },
    formatOnlineTime(dateTime) {
        const now = moment();
        const targetTime = moment(dateTime);
        const duration = moment.duration(now.diff(targetTime));

        let result = '';

        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        if (days > 0) {
            result = `${days}d `;
            if (hours > 0 || minutes > 0) {
                result += `${hours}h `;
            }
            if (minutes > 0) {
                result += `${minutes}p `;
            }
        } else if (hours > 0) {
            result = `${hours}h `;
            if (minutes > 0) {
                result += `${minutes}p `;
            }
        } else if (minutes > 0) {
            result = `${minutes}p `;
            if (seconds > 0) {
                result += `${seconds}s `;
            }
        } else if (seconds > 0) {
            result = `${seconds}s `;
        }

        return result.trim();
    },
    ShowLoading() {
        if (this.loadingInstance) {
            this.loadingInstance.close()
        }
        this.loadingInstance = ElLoading.service({ fullscreen: true })
    },
    CloseLoading() {
        if (this.loadingInstance) {
            this.loadingInstance.close()
        }
    },


    getScreenType() {
        let width = window.innerWidth;
        if (width <= 450) return _enum.ScreenType.Mobile;
        else if (width <= 1024) return _enum.ScreenType.Tablet;
        return _enum.ScreenType.Computer;
    }
}