import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist'

import state from './state';
import getters from './getters';
import mutations from './mutation';
import actions from './actions';

import user from './user/user-const.js';
import device from './device/device-const.js';
import groupDevice from './groupDevice/group-device-const.js';
// @Summary 登录
// @Description 登录
// @Produce json
// @Param body body controllers.LoginParams true "body参数"
// @Success 200 {string} string "ok" "返回用户信息"
// @Failure 400 {string} string "err_code：10002 参数错误； err_code：10003 校验错误"
// @Failure 401 {string} string "err_code：10001 登录失败"
// @Failure 500 {string} string "err_code：20001 服务错误；err_code：20002 接口错误；err_code：20003 无数据错误；err_code：20004 数据库异常；err_code：20005 缓存异常"
// @Router /user/person/login [post]
import setupMiningCoin from './setupMiningCoin/setup-mining-coin-const';
import taskScript from './taskScript/task-script-const';
import job from './job/job-const';
import setupAccount from './setupAccount/setup-account-const.js';
import coin from './coin/coin-const.js';
import proxy from './proxy/proxy-const.js'
import log from './log/log-const.js'
// const vuexLocal = new VuexPersistence({
//     key: 'MYAPP',
//     storage: window.localStorage
// })

export const moduleStore = {
    getters,
    state,
    actions,
    mutations,
    namespaced: true,
    modules: {
        user: user,
        device: device,
        groupDevice: groupDevice,
        setupMiningCoin: setupMiningCoin,
        taskScript: taskScript,
        job: job,
        setupAccount: setupAccount,
        coin: coin,
        proxy: proxy,
        log: log
    },
    // plugins: [vuexLocal.plugin]
}

export const store = new createStore(moduleStore);