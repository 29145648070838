const env = {
    node: process.env.NODE_ENV || "development",
    isProduction: process.env.NODE_ENV === "production",
    isTest: process.env.NODE_ENV === "test",
    isDevelopment: process.env.NODE_ENV === "development",
    enpoint: process.env.VUE_APP_BASE_API,
    urlApi: process.env.VUE_APP_SERVERHOST,
    urlWS: process.env.VUE_APP_WSHOST
};
console.log(env);

export default env;