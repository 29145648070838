<template>
  <div>
    <div class="title-common-option p-4">Tùy chọn chung</div>
    <div class="pl-4">
      <div>
        <div class="sub-title-common-option">Đào coin</div>


        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Mặc định chọn</div>
          <div class="w-32">
            <h-combobox :data="JobsCoin" :display="'jobName'" :keyValue="'jobId'" @change="onChangeJobCoin"
              :value="currentItem.globalConfig.jobId"></h-combobox>
          </div>
        </div>

        <div class="flex items-center mt-2.5">
          <div class="flex items-center mt-2.5">
            <div class="w-36 mr-2">Thời gian gửi báo cáo</div>
            <div class="w-32">
              <a-input v-model:value="currentItem.globalConfig.reportTime" />
            </div>
            <div class="ml-4">Giây</div>
          </div>
        </div>
      </div>
      <div>
        <div class="sub-title-common-option mt-6">Thiết lập chạy adb</div>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Máy chủ appium</div>
          <div class="w-64">
            <a-input v-model:value="currentItem.globalConfig.appium" />
          </div>
          <div class="ml-4">Để trống sẽ tự chạy máy chủ trên thiết bị</div>
        </div>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Số thiết bị/1 proxy</div>
          <div class="w-64">
            <a-input v-model:value="currentItem.globalConfig.deviceNumber" />
          </div>
        </div>
        
      </div>
      <div>
        <div class="sub-title-common-option mt-6">Thiết lập termux</div>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Thiết lập tài khoản</div>
          <div class="">
            <span>echo "{{ UserId }}" > /data/data/com.termux/files/home/tbhkey</span>
          </div>
        </div>
      </div>
      <div class="flex items-center mt-2.5 pt-2.5" style="border-top:1px solid #D9D9D9;">
        <div><h-button class="mr-3" :classButton="'btn-cancel'" :title="'Hủy bỏ thay đổi'"
            @click="cancelChangeSetting"></h-button></div>
        <div><h-button :title="'Lưu thiết lập'" @click="saveChangeSetting" :classButton="'btn-ok'"></h-button></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ModuleDevice, ModuleJob, ModuleUser } from "@/store/module-const";
import { mapGetters, mapActions } from 'vuex'
import _enum from '@/enum/enum'
import commonFunc from "@/common/commonFunc";

import messageNotification from "@/utils/messageNotification/messageNotification";

</script>

<script>
export default {
  props: {
  },
  computed: {
    ...mapGetters(ModuleJob, [
      'JobsCoin'
    ]),
    ...mapGetters(ModuleUser, [
      'User',"UserId"
    ]),
  },
  data() {
    return {
      currentItem: {
        globalConfig: {
          jobId: '',
          reportTime: '',
          appium: '',
          deviceNumber: ''
        }
      },
    }
  },
  watch: {

  },
  async created() {
    await this.getUserInfo();
    await this.getDataCoin();
  },
  methods: {
    ...mapActions(ModuleJob, [
      'deleteJob',
      'getCoinJobs'
    ]),
    ...mapActions(ModuleUser, [
      'getByUserName',
      'updateUser'
    ]),
    async getDataCoin() {
      await this.getCoinJobs({ type: 0 });
    },

    async getUserInfo() {
      await this.getByUserName();
      this.setDataDefault();
    },
    async saveChangeSetting() {
      let res = await this.updateUser(this.currentItem);
      if (res) {
        await this.getUserInfo();
        messageNotification.messageNotification("Lưu thành công");
      }
    },
    cancelChangeSetting() {
      this.setDataDefault();
    },
    onChangeJobCoin(value) {
      this.currentItem.globalConfig.jobId = value;
    },

    setDataDefault() {
      this.currentItem = _.cloneDeep(this.User)
      if (!this.currentItem.globalConfig) {
        this.currentItem.globalConfig = {
          jobId: '',
          reportTime: '',
          appium: '',
          deviceNumber: ''
        }
      }
      this.jobId = this.currentItem.globalConfig.jobId;
    }
  }
}
</script>


<style lang="scss" scoped>
.title-common-option {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-title-common-option {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
