<template>
  <div class="h-full">
    <div class="flex items-center justify-between mt-4 pl-4 pr-4">
      <div class="title-setting">Thiết lập kịch bản</div>
    </div>

    <div class="flex items-center justify-between mt-4 pr-5 pl-2">
      <div class="w-60">
        <a-input v-show="!selected || selected.length <= 0" v-model:value="params.search"
          placeholder="Nhập tên để tìm kiếm" @keydown="filterData" />
      </div>
      <div>
        <h-button :title="'Thêm mới'" :type="'primary'" @click="onClickAddScript"></h-button>
      </div>
    </div>
    <div class="mt-3 grid-content">
      <h-grid :columns="columns" :data="TaskScripts" :checkbox="true" :keyField="'scriptId'" :pagination="true"
        :dataPagination="totalInfo" @changePagination="changePagination" @onSelectedAll="onSelectedAll"
        @onSelected="onSelected">
        <template #widget="slotProps">
          <SetupScriptWidget @widgetEvent="widgetEvent" :data="slotProps.data" />
        </template>
        <template #template="slotProps">
          <div v-if="slotProps && slotProps.column && slotProps.data">
            {{ $filters.SocialNetWork(slotProps.data[slotProps.column["dataIndex"]]) }}
          </div>
        </template>
      </h-grid>
    </div>
    <SetupScriptDetail @okDialog="okDialogTaskScriptDetail" @cancelDialog="cancelDialogTaskScriptDetail"
      :isShow="isShowDetail" :title="`Thêm kịch bản chạy`" :currentItem="taskScript" :mode="mode" />
  </div>
</template>

<script setup>
import { ModuleTaskScript } from "@/store/module-const";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import SetupScriptWidget from "./SetupScriptWidget.vue";
import SetupScriptDetail from "./SetupScriptDetail.vue";
import _enum from "@/enum/enum";
import commonFunc from "@/common/commonFunc";
import messageNotification from "@/utils/messageNotification/messageNotification";
</script>

<script>
export default {
  props: {},
  computed: {
    ...mapGetters(ModuleTaskScript, ["TaskScripts", "TotalInfo"]),
  },
  data() {
    return {
      columns: [],
      selected: [],
      searchText: "",
      isShowDetail: false,
      mode: _enum.Mode.Add,
      taskScript: {},
      params: {
        search: ''
      }
    };
  },
  async created() {
    this.initDataStatic();
    await this.getData();
  },
  methods: {
    ...mapActions(ModuleTaskScript, [
      "getTaskScripts",
      "updateTaskScript",
      "createTaskScript",
      "deleteTaskScript",
    ]),
    initDataStatic() {
      this.columns = [
        {
          title: "Tên kịch bản",
          dataIndex: "scriptName",
          sorter: (a, b) => a.scriptName - b.scriptName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Loại",
          dataIndex: "type",
          sorter: (a, b) => a.type - b.type,
          sortDirections: ["descend", "ascend"],
          template: true,
        },
        {
          title: "Param thiết lập",
          dataIndex: "paramSetting",
          sorter: (a, b) => a.paramSetting - b.paramSetting,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Chức năng",
          dataIndex: "action",
        },
      ];
    },

    async getData() {
      await this.getTaskScripts({ search: this.params.search });
    },

    changePagination(params) { },

    onSelected(params) {
      this.selected = [...params.selectedRows];
    },

    onSelectedAll(params) {
      this.selected = [...params.selectedRows];
    },

    async widgetEvent(payload) {
      if (!payload || !payload.data || !payload.event) return;
      this.taskScript = _.cloneDeep(payload.data);
      switch (payload.event) {
        case _enum.Event.Edit:
          this.isShowDetail = true;
          this.mode = _enum.Mode.Edit;
          break;
        case _enum.Event.Delele:
          await this.deleteTaskScriptAsync({
            scriptId: this.taskScript ? this.taskScript.scriptId : "",
          });

          await this.getData();

          break;
      }
    },

    deleteTaskScriptAsync: _.debounce(async function (script) {
      let res = await this.deleteTaskScript(script);
      if (res) {
        messageNotification.messageNotification("Xóa thành công");
      } else {
        messageNotification.messageNotification("Xóa thất bại");
      }
    }, 300),

    filterData: _.debounce(async function(){
      const me = this;
      await me.getData();
    }, 200),

    onClickRefresh() { },

    onClickAddScript() {
      this.mode = _enum.Mode.Add;
      this.isShowDetail = true;
    },

    async okDialogTaskScriptDetail() {
      await this.getData();
      if (this.mode == _enum.Mode.Edit) {
        messageNotification.messageNotification("Sửa thành công");
      } else {
        messageNotification.messageNotification("Thêm thành công");
      }
      this.resetData();
    },

    cancelDialogTaskScriptDetail() {
      this.resetData();
    },
    resetData() {
      this.isShowDetail = false;
      this.mode = _enum.Mode.Add;
      this.taskScript = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-content {
  height: calc(100% - 109px);
  width: 100%;
  overflow: auto;
}

.title-setting {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
