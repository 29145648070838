<template>
  <div v-if="isShow">
    <h-dialog :title="title" :footerDefault="footerDefault" :isShow="isShow" @okDialog="okDialog"
      @cancelDialog="cancelDialog" :width="800">
      <template #content>
        <div class="flex items-center">
          <div class="text-proxy-type" :class="currentItem.status == _enum.ProxyType.Turn ? 'text-proxy-type-active' : ''"
            @click="changeTypeProxy">
            Xoay</div>
          <div class="text-proxy-type"
            :class="currentItem.status == _enum.ProxyType.Static ? 'text-proxy-type-active' : ''"
            @click="changeTypeProxy">
            Tĩnh</div>
        </div>
        <div class="flex items-center mt-2.5" v-if="currentItem.status == _enum.ProxyType.Turn">
          <div class="text-row">Link API</div>
          <a-textarea v-model:value="currentItem.api" :rows="1" />
        </div>
        <div class="mt-2.5">
          <div>Nhập theo cấu trúc (mỗi proxy 1 dòng): IP:port | username | pass</div>
          <div class="flex items-center mt-2.5">
            <div class="text-row">Nội dung</div>
            <a-textarea v-model:value="currentItem.content" :rows="8" />
          </div>
        </div>
      </template>
    </h-dialog>
  </div>
  <div v-else></div>
</template>

<script setup>
import _enum from '@/enum/enum'
import { ModuleProxy, ModuleUser } from '@/store/module-const'
import { mapGetters, mapActions } from 'vuex'
import commonFunc from '@/common/commonFunc'
</script>

<script>
import { forEach } from 'lodash'
export default {
  props: {
    isShow: {
      default: false,
      typeof: Boolean
    },
    title: {
      default: '',
      typeof: String
    },
    mode: {
      default: 1,
      typeof: Number
    },
    currentItem: {
      default: {
        status: _enum.ProxyType.Turn
      },
      typeof: Object
    }
  },
  computed: {
    ...mapGetters(['userId']),
  },
  data() {
    return {
      footerDefault: {
        cancelText: 'Hủy bỏ',
        okText: 'Nhập',
        cancelStyle: {},
        okStyle: {},
      },
    }
  },
  created() {
    this.initDataStatic();
  },
  methods: {
    ...mapActions(ModuleProxy, [
      'updateProxy',
      'createProxy',
      'createManyProxy'
    ]),
    initDataStatic() {
      this.tabs = [
        {
          title: 'Youtube',
          type: _enum.SocialNetWork.Youtube
        },
        {
          title: 'Tiktok',
          type: _enum.SocialNetWork.Tiktok
        },
        {
          title: 'Facebook',
          type: _enum.SocialNetWork.Facebook
        }
      ]
      this.columns = [
        {
          title: "Loại tài khoản",
          dataIndex: "accountType",
          sorter: (a, b) => a.accountType - b.accountType,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Họ và đệm",
          dataIndex: "firstName",
          sorter: (a, b) => a.firstName - b.firstName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Tên",
          dataIndex: "lastName",
          sorter: (a, b) => a.lastName - b.lastName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Username",
          dataIndex: "userName",
          sorter: (a, b) => a.userName - b.userName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Mật khẩu",
          dataIndex: "password",
          sorter: (a, b) => a.password - b.password,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Thiết bị đăng nhập",
          dataIndex: "deviceName",
          sorter: (a, b) => a.deviceName - b.deviceName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Chức năng",
          dataIndex: "action",
        },
      ]
    },

    cancelDialog() {
      this.$emit('cancelDialog');
    },
    async okDialog() {
      const me = this;
      if (this.mode == _enum.Mode.Edit) {
        await this.updateProxy(this.currentItem);
        this.$emit('okDialog', this.currentItem);
        return
      }

      let proxies = [];
      let proxyInfos = this.currentItem.content.split('\n');
      if (proxyInfos && proxyInfos.length > 0) {
        proxyInfos.forEach(proxyInfo => {
          let proxy = me.standardDataProxy(proxyInfo);
          proxies.push(proxy);
        });
      }

      await this.createManyProxy(proxies);
      this.$emit('okDialog', proxies);
    },
    changeTypeProxy() {
      if (this.currentItem.status == _enum.ProxyType.Turn) {
        this.currentItem.status = _enum.ProxyType.Static;
        return;
      }
      if (this.currentItem.status == _enum.ProxyType.Static) {
        this.currentItem.status = _enum.ProxyType.Turn;
        return
      }
    },

    standardDataProxy(proxyInfo) {
      let proxy = {
        proxyId: commonFunc.uuidv4(),
        userId: this.userId,
        api: this.currentItem.api,
        status: this.currentItem.status
      };
      if (!proxyInfo) return proxy;
      proxyInfo = proxyInfo.replaceAll(' ', '');
      let infos = proxyInfo.split('|');
      if (infos) {
        if (infos[0]) {
          let ipAndPort = infos[0].split(':');
          if (ipAndPort) {
            if (ipAndPort[0]) proxy.host = ipAndPort[0];
            if (ipAndPort[1]) proxy.port = ipAndPort[1];
          }
        }
        if (infos[1]) {
          proxy.username = infos[1];
        }
        if (infos[2]) {
          proxy.pass = infos[2];
        }
      }
      return proxy;
    }

  }
}
</script>

<style lang="scss" scoped>
.grid-devies {
  min-height: 200px;
  max-height: 450px;
  overflow: auto;
}

.text-row {
  display: flex;
  width: 96px;
  padding: 5px 0px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.text-proxy-type {
  display: flex;
  padding: 5px 16px;
  align-items: center;
  gap: 10px;
  background: var(--fill-color-blank, #FFF);
  border: 1px solid #DCDFE6;
  color: var(--text-color-regular, #606266);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.01px;
  cursor: pointer;
}

.text-proxy-type-active {
  display: flex;
  padding: 5px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 4px 0px 0px 4px;
  background: var(--color-primary, #409EFF);
  color: var(--color-white, var(--white, #FFF));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.01px;
  cursor: pointer;
}
</style>