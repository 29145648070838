<template>
  <div class="w-full max-h-full h-full-vh">
    <layout-aside-content :widthAside="300">
      <template #aside>
        <HeaderGroupDevice />
      </template>
      <template #content>
        <ContentGroupDevice />
      </template>
    </layout-aside-content>
  </div>
</template>

<script setup>
import HeaderGroupDevice from './HeaderGroupDevice.vue'
import ContentGroupDevice from './ContentGroupDevice.vue'
import _enum from "@/enum/enum";
import _ from "lodash";
import { ModuleGroupDevice } from "@/store/module-const";
import { mapGetters, mapActions, mapMutations } from "vuex";
</script>


<script>
export default {
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  async created() {
    const me = this;
    await me.getData();
  },
  methods: {
    ...mapActions(ModuleGroupDevice, [
      'getGroupDevices'
    ]),

    ...mapMutations(ModuleGroupDevice, [
      'updateGroupDevices'
    ]),
    async getData() {
      await this.getGroupDevices();
    },
  },

}
</script>
<style lang="scss" scoped></style>