<template>
    <div v-if="isShow">
        <h-dialog :title="title" :footerDefault="footerDefault" :isShow="isShow" @okDialog="okDialog"
            @cancelDialog="cancelDialog" :width="900">
            <template #content>
                <div>
                    <a-textarea v-model:value="currentItem.content" placeholder="Nhập nội dung" :rows="4" />
                </div>
                <div class="mt-2.5 grid-devies">
                    <h-grid :columns="columns" :data="devices" :keyField="'deviceId'">
                        <template #template="slotProps">
                            <ResultColumn :data="slotProps.data" />
                        </template>
                    </h-grid>
                </div>
            </template>
        </h-dialog>
    </div>
    <div v-else></div>
</template>

<script setup>
import _enum from '@/enum/enum'
import ResultColumn from './detail/ResultColumn.vue'

</script>

<script>
export default {
    props: {
        isShow: {
            default: false,
            typeof: Boolean
        },
        title: {
            default: '',
            typeof: String
        },
        currentItem: {
            default: {},
            typeof: Object
        },
        devices: {
            default: [],
            typeof: Array
        }
    },
    data() {
        return {
            columns: [
                {
                    title: 'Tên',
                    dataIndex: 'deviceName',
                    width: 200
                },
                {
                    title: 'Kết quả',
                    dataIndex: 'template'
                }
            ],
            footerDefault: {
                cancelText: 'Hủy bỏ',
                okText: 'Gửi lệnh',
                cancelStyle: {},
                okStyle: {}
            },
            currentItem: {}
        }
    },
    methods: {
        cancelDialog() { this.$emit('cancelDialog') },
        okDialog() { 
            
            this.$emit('okDialog', this.currentItem) 
        }
    }
}
</script>

<style lang="scss" scoped>
.grid-devies {
    min-height: 200px;
    max-height: 450px;
    overflow: auto;
}
</style>