<template>
  <div  v-if="title">
    {{ title }}
  </div>
  <a-checkbox v-model:checked="checked" @change="handleChange" :disabled="disabled"></a-checkbox>
</template>
<script>
</script>

<script>
export default {
  props: {
    icon: {
      typeof: String,
      default: ''
    },
    title: {
      typeof: String,
      default: ''
    },
    checkOnOf: {
      type: Boolean,
      default: false
    },
    /**
     * Custom css checkbox
     */
    checkboxCus: {
      default: '',
      typeof: String
    },
    value: {type: Boolean,
      default: false},
      disabled: {type: Boolean,
      default: false},
  },
  watch: {
    checked(newVal,oldVal){
      this.$emit('update:value', newVal)
    }
  },
  created() {
    this.checked = this.value;
  },
  data() {
    return {
      checked : false
    }
  },
  methods: {
    handleChange(value){
    }
  },
}
</script>


<style lang="scss" scoped>
</style>

