export default {
    SET_DEVICES(state, devices) {
        state.devices = devices;
    },
    UPDATE_DEVICES_STATUS(state, data) {
        let device = state.devices.find(x => x.deviceId == data.deviceId)
        if (device) {
            device.status = data.status;
        }
    },
    UPDATE_DEVICES_LASTCHECK(state, data) {
        let device = state.devices.find(x => x.deviceId == data.deviceId)
        if (device) {
            device.lastCheck = data.lastCheck;
        }
    },
    UPDATE_DEVICE(state, data) {
        if (!state.isUpdateReport) {
            let device = state.devices.find(x => x.deviceId == data.deviceId)
            if (device) {
                if (data.deviceName)
                    device.deviceName = data.deviceName;
                if (data.status)
                    device.status = data.status;
                if (data.lastCheck)
                    device.lastCheck = data.lastCheck;
                if (data.adbWifi)
                    device.adbWifi = data.adbWifi;
                if (data.tempe)
                    device.tempe = data.tempe;
                if (data.cpuUse)
                    device.cpuUse = data.cpuUse;
                if (data.hash)
                    device.hash = data.hash;
                if (data.cpumhz)
                    device.cpumhz = data.cpumhz;
                if ('isMining' in data)
                    device.isMining = data.isMining;
                if (data.miningCoin)
                    device.miningCoin = data.miningCoin;
            }
        }

    },
    DELETE_DEVICE(state, data) {
        let index = state.devices.findIndex(x => x.deviceId == data.deviceId)
        if (index !== -1) {
            state.devices.splice(index, 1);
        }
    },
    INCREMENT_TOTAL_ONLINE(state) {
        state.totalOnline++;
    },
    DECREMENT_TOTAL_ONLINE(state) {
        state.totalOnline--;
    },
    SET_TOTAL_ONLINE(state, totalOnline) {
        if (totalOnline) {
            state.totalOnline = totalOnline
        }
    },

    ADD_MESSAGE(state, message) {
        state.messages.unshift(message);
        if (state.messages.length > 100) {
            state.messages.pop();
        }
    },
    TASK_RESULT(state, data) {
        const device = state.deviceSelected.find(x => x.deviceId === data.deviceId);
        if (device) {
            device.message = data.message;
        }
    },

    changeLoadingStatus(state, res) {
        state.Loading = res;
    }
}