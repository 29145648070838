<template>
  <div class="h-full setup-job w-full">
    <div class="flex items-center w-full setup-job-title ml-4">
      <div class="title-jobs">Thiết lập công việc</div>
    </div>
    <div class="flex w-full setup-job-content">
      <div class="jobs-info">
        <div class="flex items-center justify-between title-job-info w-full">
          <div class="text-title">Tên công việc</div>
          <div class="icon-16 icon-add cursor-pointer" @click="onClickAddJob"></div>
        </div>
        <div class="flex mt-1 pl-2.5 pr-2.5">
          <a-input v-model:value="currentItem.searchText" placeholder="Nhập tên để tìm..." />
        </div>
        <div class="jobs mt-3">
          <div class="job flex" v-for="(job, index) in Jobs" :key="index"
            :class="job.jobId == Job.jobId ? 'job-active' : ''">
            <div class=" flex items-center justify-between w-full">
              <div class="job-name cursor-pointer" @click="onClickChangeJob(job)">{{ job.jobName }}</div>
              <div class="flex items-center">
                <div class="icon-16 icon-copy mr-2.5 cursor-pointer"></div>
                <div class="icon-16 icon-delete cursor-pointer" @click="onClickDeleteJob(job, index)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions-job">
        <div v-if="Job">
          <div class="flex p-4 items-center justify-between">
            <div class="title-script-name">{{ Job.jobName }}</div>
            <div>
              <h-button :title="'Thêm hành động'" :type="'primary'" @click="onClickAddAction"></h-button>
            </div>
          </div>
          <div class="mt-3">
            <h-grid :columns="columns" :data="Job.jobScripts" :checkbox="true" :keyField="'deviceId'">
              <template #widget="slotProps">
                <ActionJobWidget @widgetEvent="widgetEvent" :data="slotProps.data" />
              </template>
            </h-grid>
          </div>
        </div>
        <div v-else class="action-no-data">Không có dữ liệu</div>
      </div>
    </div>
    <SetupWorkDetail @okDialog="okDialogJobDetail" @cancelDialog="cancelDialogJobDetail" :isShow="isShowDetail"
      :title="`Thêm công việc`" :currentItem="job" :mode="mode" />
    <ActionJobDetail @okDialog="okDialogActionJobDetail" @cancelDialog="cancelDialogActionJobDetail"
      :isShow="isShowActionDetail" :title="`Thêm hành động ${Job.jobName}`" :currentItem="actionJob" :mode="mode" />
  </div>
</template>
<script setup>
import { ModuleJob } from "@/store/module-const";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import SetupWorkDetail from './SetupWorkDetail'
import ActionJobDetail from './ActionJobDetail'
import ActionJobWidget from './ActionJobWidget'
import messageNotification from "@/utils/messageNotification/messageNotification";
import _enum from "@/enum/enum";
</script>

<script>
export default {
  data() {
    return {
      jobActive: null,
      currentItem: {},
      columns: [],

      selected: [],
      isShowDetail: false,
      isShowActionDetail: false,
      mode: _enum.Mode.Add,
      job: {},
      actionJob: {}
    }
  },
  computed: {
    ...mapGetters(ModuleJob, [
      'Jobs',
      'Job'
    ])
  },
  async created() {
    this.initDataStatic();
    await this.getData();
  },
  methods: {
    ...mapActions(ModuleJob, [
      'getJobs',
      'deleteJob',
      'updateJob'
    ]),
    ...mapMutations(ModuleJob, [
      'updateJobActive'
    ]),
    initDataStatic() {
      this.columns = [
        {
          title: "Tên kịch bản",
          dataIndex: "scriptName",
          sorter: (a, b) => a.scriptName - b.scriptName,
          sortDirections: ["descend", "ascend"],
          width : 200
        },
        {
          title: "Số lần lặp",
          dataIndex: "repeat",
          sorter: (a, b) => a.repeat - b.repeat,
          sortDirections: ["descend", "ascend"],
          width : 200
        },
        {
          title: "Tham số",
          dataIndex: "param",
          sorter: (a, b) => a.param - b.param,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Chức năng",
          dataIndex: "action",
        },
      ];
    },
    async getData() {
      await this.getJobs();
    },
    onClickAddJob() {
      this.isShowDetail = true;
    },
    onClickAddAction() {
      this.isShowActionDetail = true;
    },
    async okDialogJobDetail() {
      await this.getData();
      if (this.mode == _enum.Mode.Edit) {
        messageNotification.messageNotification("Sửa thành công");
      } else {
        messageNotification.messageNotification("Thêm thành công");
      }
      this.resetData();
    },

    cancelDialogJobDetail() {
      this.resetData();
    },
    async okDialogActionJobDetail() {
      await this.getData();
      if (this.mode == _enum.Mode.Edit) {
        messageNotification.messageNotification("Sửa hành động thành công");
      } else {
        messageNotification.messageNotification("Thêm hành động thành công");
      }
      this.resetData();
    },

    cancelDialogActionJobDetail() {
      this.resetData();
    },
    resetData() {
      this.isShowDetail = false;
      this.mode = _enum.Mode.Add;
      this.job = {};
      this.isShowActionDetail = false;
      this.actionJob = {}
    },

    async onClickChangeJob(job) {
      await this.updateJobActive(job)
    },
    onClickDeleteJob: _.debounce(async function (job, index) {
      let res = await this.deleteJob(job)
      if (res) {
        await this.getData();
        messageNotification.messageNotification("Xóa thành công");
      } else {
        messageNotification.messageNotification("Xóa thất bại");
      }
    }, 300),

    async widgetEvent(payload) {
      if (!payload || !payload.data || !payload.event) return;
      this.actionJob = _.cloneDeep(payload.data);
      switch (payload.event) {
        case _enum.Event.Edit:
          this.isShowActionDetail = true;
          this.mode = _enum.Mode.Edit;
          break;
        case _enum.Event.Delele:
          let job = _.cloneDeep(this.Job);
          if (!job.jobScripts) {
            job.jobScripts = [];
          } else {
            job.jobScripts = job.jobScripts.filter(x => x.id != this.actionJob.id);
          }

          job.jobScripts = job.jobScripts.map(x => {
            delete x.param;
            return x;
          })
          let res = await this.updateJob(job);
          if (res) {
            messageNotification.messageNotification("Xóa hành động thành công");
          }
          await this.getData();
          break;
      }
    },
  }
}

</script>

<style lang="scss" scoped>
@import url("@/assets/contents/scss/views/setting/setupWork/setupWork.scss");
</style>
