<template>
  <div class="flex justify-center w-full h-full-vh the-menu">
    <div>
      <div class="h-1/3 flex items-start justify-center pt-4">
        <div class="icon-36 icon-app cursor-pointer"></div>
      </div>
      <div class="h-1/3 flex items-center justify-center">
        <div>
          <div class="icon-menu" v-for="(menu, index) in menus" :key="index" @click="onClickMenu(menu, index)"
            :class="menu.isActive || $route.path == menu.router ? 'menu-active' : ''">
            <div :class="menu.icon"></div>
          </div>
          <!-- <div class="icon-menu">
            <div class="icon-36 icon-menu icon-menu-task mt-6 cursor-pointer"></div>
          </div>
          <div class="icon-menu">
            <div class="icon-36 icon-menu-setting mt-6 cursor-pointer"></div>
          </div> -->
        </div>
      </div>
      <div class="h-1/3 flex items-end justify-center pb-4">
        <h-dropdown :data="options" :display="'text'" @click="optionClick" :value="groupDevice" :placement="'topRight'">
          <template #itemView>
            <div class="font-bold" style="padding: 5px 12px;">{{ User.username }}</div>
            <div style="padding: 5px 12px;">Số thiết bị: <span class="font-bold">{{ User.maxDevice }}</span></div>
          </template>
          <template v-slot:icon>
            <div class="icon-36 icon-user cursor-pointer"></div>
          </template>
        </h-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { mapGetters } from 'vuex';
import { ModuleUser } from "@/store/module-const";
</script>

<script>

export default {
  data() {
    return {
      options: [
        {
          text: "Đăng xuất",
          action: 'logout'
        }
      ],
      menus: [
        {
          icon: 'icon-36 icon-menu-device mt-6 cursor-pointer',
          router: '/device'
        },
        // {
        //   icon: 'icon-36 icon-menu icon-menu-task mt-6 cursor-pointer',
        //   router: '/task'
        // },
        {
          icon: 'icon-36 icon-menu-setting mt-6 cursor-pointer',
          router: '/setup-mining-coin'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(ModuleUser, [
      'User'
    ])
  },
  watch: {

  },
  async created() {
    const me = this;
  },
  methods: {

    optionClick(item) {
      if (!item) return
      if (item.action == 'logout') {
        localStorage.removeItem('userId');
        this.$router.push('/login');
      }
    },
    onClickMenu(menu, index) {
      if (!menu || !menu.router) return;
      this.menus.forEach((element) => {
        element.isActive = false;
      });
      this.menus[index].isActive = true;
      this.$router.push(menu.router);
    }
  },

}
</script>

<style lang="scss" scoped>
.the-menu {
  width: 68px;
  height: 100vh;
  flex-shrink: 0;
  background: var(--blue, #0091FF);
}

.menu-active {
  border-radius: 8px;
  opacity: 0.5;
  background: #abaaaa;
}
</style>


