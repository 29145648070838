<template>
  <a-layout>
    <a-layout-header class="headerStyle">
      <div class="logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none">
          <g clip-path="url(#clip0_1124_4256)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.4096 26.9385V15.8561L30 10.3191V20.9771C30 21.2463 29.8656 21.4791 29.6325 21.6137L20.4096 26.9385ZM8.87297 21.4304H1.13496C0.508184 21.4304 0 20.9222 0 20.2955C0 19.6687 0.508184 19.1605 1.13496 19.1605H8.8326C10.1636 19.1605 10.1636 17.0348 8.8326 17.0348H4.20686C3.58008 17.0348 3.07184 16.5267 3.07184 15.8999C3.07184 15.2731 3.58008 14.7649 4.20686 14.7649H7.82479C9.1558 14.7649 9.1558 12.6393 7.82479 12.6393H1.13502C0.508242 12.6393 5.85938e-05 12.1311 5.85938e-05 11.5043C5.85938e-05 10.8775 0.508242 10.3693 1.13502 10.3693H8.90971L18.4133 15.8563V26.9385L8.87297 21.4304ZM9.81381 8.59207L19.4114 14.1332L29.009 8.59201L19.4298 3.06152H13.1448C12.518 3.06152 12.0098 3.56971 12.0098 4.19648C12.0098 4.82326 12.518 5.33145 13.1448 5.33145H14.473C14.595 5.33145 14.6972 5.4099 14.7288 5.52773C14.7604 5.64557 14.7111 5.76463 14.6054 5.82568L9.81381 8.59207ZM9.26748 3.06152C9.89426 3.06152 10.4024 3.56971 10.4024 4.19648C10.4024 4.82326 9.89426 5.33145 9.26748 5.33145H2.36883C1.74205 5.33145 1.23381 4.82326 1.23381 4.19648C1.23381 3.56971 1.74199 3.06152 2.36883 3.06152H9.26748Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_1124_4256">
              <rect
                width="30"
                height="30"
                fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="category-title">
        <div class="category-name">Tất cả thiết bị</div>
        <div class="arrow-down">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none">
            <path
              d="M6.5 8L0.870835 0.499999L12.1292 0.5L6.5 8Z"
              fill="#D9D9D9" />
          </svg>
        </div>
      </div>
      <div class="sidebar-menu">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="20"
          viewBox="0 0 24 20"
          fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 15.0022H21V13.3348H3V15.0022ZM3 10.8338H21V9.16638H3V10.8338ZM3 4.99792V6.66531H21V4.99792H3Z"
            fill="white"
            fill-opacity="0.87" />
        </svg>
      </div>
    </a-layout-header>

    <a-layout-content class="contentStyle">
        <DevicesList></DevicesList>
    </a-layout-content>
    <a-layout-footer :style="footerStyle">Footer</a-layout-footer>
  </a-layout>
</template>
<script>
export default {}
</script>
<script setup>
// import _enum from '@/enum/enum'
// import { mapGetters, mapMutations } from 'vuex'
import DevicesList from '@/views/devices/DevicesList.vue'
// import { ModuleUser } from '@/store/module-const'
</script>
<style scoped>
.headerStyle {
  display: flex;
  padding: 8px 9px;
  align-items: center;
  gap: 10px;
  background: #383838;
}
.logo {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.category-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.category-title .category-name {
  color: var(--white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.arrow-down {
  width: 13px;
  height: 8px;
  fill: #d9d9d9;
}
.sidebar-menu {
  width: 24px;
  height: 20.009px;
  flex-shrink: 0;
}
.contentStyle {
  background: #2c2c2c;
  padding: 16px;
}
</style>
