export default {
    userConfig: state => state.userConfig,
    devices: state => state.devices,
    messages: state => state.messages,
    userId: state => localStorage.getItem('userId'),
    totalOnline: state => state.totalOnline,
    totalDevices: state => state.devices.length,
    deviceSelected: state => state.deviceSelected,
    totalSelectedDevices: state => state.deviceSelected.length,
    userData: state => state.userData,
    isUpdateReport: state => state.isUpdateReport,
    Loading : state => state.Loading
}