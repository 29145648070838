<template>
  <div>
    <a-input v-model:value="props.value" placeholder="Nhập tên/Id thiết bị để tìm kiếm..." 
    @keydown="onKeydown"
      :style="{ width: props.width + 'px' }" 
      @blur="onBlur"/>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const emit = defineEmits(['input', 'blur', 'keydown'])
const props = defineProps({
  value: {
    default: '',
    typeof: String
  },  
  title: {
    default: '',
    typeof: String
  },
  type: {
    default: 'text',
    typeof: String
  },
  height: {
    default: 36,
    typeof: Number
  },
  width: {
    default: 100,
    typeof: Number
  },
  fontSize: {
    default: 14,
    typeof: Number
  },
  fontFamily: {
    default: '',
    typeof: String
  },
  color: {
    default: '',
    typeof: String
  },
  classHover: {
    default: '',
    typeof: String
  },
  classInput: {
    default: '',
    typeof: String
  },
  classActive: {
    default: '',
    typeof: String
  },
  icon: {
    default: '',
    typeof: String
  },
  position: {
    default: '',
    typeof: String
  },
  customInput: {
    default: '',
    typeof: String
  }
})

const handleInput = (event) => {
  emit('input', event.target.value)
}
const onBlur = (event) => {
  emit('blur')
}

const onKeydown = (event) => {
  emit('keydown')
}

const positionIcon = () => {
  if (props.position == 'right') return 'position-icon-right'

  if (props.position == 'center') return 'position-icon-center'
  return 'position-default'
}
</script>

<style lang="scss" scoped>
@import '@/assets/contents/scss/components/input/input.scss';
</style>
