<template>
  <div class="logs-device">
    <div class="">
      <div class="log-title flex items-center"><span class="ml-4">Log Hệ Thống</span></div>
      <div class="log-items">
        <div
          v-for="(log, index) in Logs"
          :key="index">
          <div class="flex items-center log-item">
            <div
              :class="$filters.iconLogSystemStatus(log.logType)"
              class="icon-21"></div>
            <div class="ml-2">
              <div class="text-log">
               {{ log.logMessage }}
              </div>
              <div class="log-time">
                {{ $filters.formatDate(log.createDate) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ModuleLog } from '@/store/module-const'
import { mapGetters, mapActions } from 'vuex';
</script>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(ModuleLog, [
      'Logs'
    ])
  },
  async created() {
    await this.getLogs();
  },
  methods: {
    ...mapActions(ModuleLog, [
      'getLogs'
    ]),
    async getData() {
      await this.getLogs();
    },
  }
}

</script>
<style lang="scss" scoped>
@import url('@/assets/contents/scss/views/logSystem/logSystem.scss');
</style>