<template>
    <div class="flex items-center">
        <div class="mr-2.5 icon-16 icon-edit cursor-pointer" @click="widgetEvent(_enum.Event.Edit)"></div>
        <div class="mr-2.5 icon-16 icon-delete cursor-pointer" @click="widgetEvent(_enum.Event.Delele)"></div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import _enum from '@/enum/enum'
const props = defineProps({
    data: {
        default: {},
        typeof: Object
    }
})
const emit = defineEmits(['widgetEvent'])

const widgetEvent = (event) => {
    const payload = {
        data: props.data,
        event: event
    }
    emit('widgetEvent', payload)
}
</script>

<style lang="scss" scoped></style>