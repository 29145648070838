<template>
  <div class="w-full max-h-full h-full-vh">
    <layout-aside-content :widthAside="68">
    <template #aside>
      <the-menu class="the-menu"></the-menu>
    </template>
    <template #content>
      <the-content class="w-vh-full"></the-content>
    </template>
  </layout-aside-content>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import url('@/assets/contents/scss/layout/page.scss');
.w-vh-full{
  height: 100%;
}
</style>