<template>
    <div v-if="isShow">
        <h-dialog :title="title" :footerDefault="footerDefault" :isShow="isShow" @okDialog="okDialog"
            @cancelDialog="cancelDialog" :width="800">
            <template #content>
                <div class="flex items-center">
                    <div class="w-32 mr-2">Tên kịch bản</div>
                    <div class="w-full">
                        <h-combobox :data="TaskScripts" :display="'scriptName'" :keyValue="'scriptId'"
                            @change="onChangeScript" :value="currentItem.scriptId"></h-combobox>
                    </div>
                </div>
                <div class="flex items-center mt-2.5">
                    <div class="w-32 mr-2">Số lần lặp</div>
                    <div class="w-full">
                        <a-input v-model:value="currentItem.repeat" />
                    </div>
                </div>
                <div class="" v-if="script && script.paramSetting">
                    <div class="flex items-center mt-2.5" v-for="(param, index) in JSON.parse(script.paramSetting)"
                        :key="index">
                        <div class="w-32 mr-2">{{ param['objectLabel'] }}</div>
                        <a-textarea v-model:value="currentItem[`${param['objectName']}`]"
                            :rows="param['row'] ? param['row'] : 2" />
                    </div>
                </div>
            </template>
        </h-dialog>
    </div>
    <div v-else></div>
</template>

<script setup>
import _enum from '@/enum/enum'
import { ModuleJob, ModuleProxy, ModuleUser, ModuleTaskScript } from '@/store/module-const'
import { mapGetters, mapActions } from 'vuex'
import commonFunc from '@/common/commonFunc'
</script>

<script>
export default {
    props: {
        isShow: {
            default: false,
            typeof: Boolean
        },
        title: {
            default: '',
            typeof: String
        },
        currentItem: {
            default: {
                type: _enum.SocialNetWork.Youtube
            },
            typeof: Object
        },
        job: {
            default: {},
            typeof: Object
        },
        mode: {
            default: 1,
            typeof: Number
        },
    },
    computed: {
        ...mapGetters(ModuleJob, [
            'Job'
        ]),
        ...mapGetters(ModuleUser, ['UserId']),
        ...mapGetters(ModuleTaskScript, ['TaskScripts'])
    },
    watch: {
    },
    data() {
        return {
            footerDefault: {
                cancelText: 'Hủy bỏ',
                okText: 'Lưu',
                cancelStyle: {},
                okStyle: {}
            },
            tabActive: 0,
            script: {},
        }
    },
    async created() {
        this.initDataStatic();
        await this.getData();
        if (this.TaskScripts && this.TaskScripts.length > 0) {
            this.currentItem.scriptId = this.TaskScripts[0].scriptId;
            this.script = this.TaskScripts[0];
        }
    },
    methods: {
        ...mapActions(ModuleJob, [
            'updateJob'
        ]),
        ...mapActions(ModuleTaskScript, [
            "getTaskScripts",
        ]),
        initDataStatic() {

        },
        async getData() {
            await this.getTaskScripts();
        },
        cancelDialog() {
            this.$emit('cancelDialog');
            this.resetData();
        },
        async okDialog() {
            let job = _.cloneDeep(this.Job);
            //TODO
            //Lưu thêm gì vào jobScript lấy thêm ở đây
            // this.currentItem.script = JSON.stringify(this.script)
            if (this.mode == _enum.Mode.Edit) {
                if (!job.jobScripts) {
                    job.jobScripts = [this.currentItem];
                } else {
                    let index = job.jobScripts.findIndex(x => x.id == this.currentItem.id);
                    job.jobScripts[index] = this.currentItem;
                }
                job.jobScripts = job.jobScripts.map(x => {
                    delete x.param;
                    return x;
                })
                await this.updateJob(job);
                this.$emit('okDialog', job);
                this.resetData();
            } else {
                this.currentItem.scriptName = this.script.scriptName;
                this.currentItem.id = commonFunc.uuidv4();
                if (!job.jobScripts) {
                    job.jobScripts = [this.currentItem];
                } else {
                    job.jobScripts.push(this.currentItem);
                }
                job.jobScripts = job.jobScripts.map(x => {
                    delete x.param;
                    return x;
                })
                await this.updateJob(job);
                this.$emit('okDialog', job);
                this.resetData();
            }
        },
        onChangeApi(value) {
            this.currentItem.apiId = value;
        },
        changeTypeJob(tab, index) {
            this.tabActive = index;
            this.currentItem.type = tab.type;
        },

        onChangeScript(value) {
            this.currentItem.scriptId = value;
            if (this.TaskScripts && this.TaskScripts.length > 0) {
                this.script = this.TaskScripts.find(x => x.scriptId == value);
            }
        },

        resetData() {
            this.tabActive = 0;
            if (this.TaskScripts && this.TaskScripts.length > 0) {
                this.currentItem.scriptId = this.TaskScripts[0].scriptId;
                this.script = this.TaskScripts[0];
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.text-job-type {
    display: flex;
    padding: 5px 16px;
    align-items: center;
    gap: 10px;
    background: var(--fill-color-blank, #FFF);
    border: 1px solid #DCDFE6;
    color: var(--text-color-regular, #606266);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.01px;
    cursor: pointer;
}

.text-job-type-active {
    display: flex;
    padding: 5px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 4px 0px 0px 4px;
    background: var(--color-primary, #409EFF);
    color: var(--color-white, var(--white, #FFF));
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.01px;
    cursor: pointer;
}
</style>