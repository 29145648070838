export default {
    updateJobs(state, payload) {
        state.Jobs = [...payload];
        if (state.Jobs && state.Jobs.length > 0) {
            state.Job = state.Jobs[0];
        }
    },

    updateJobsCoin(state, payload) {
        state.JobsCoin = [...payload];
    },


    addJob(state, payload) {
        if (!state.Jobs) { state.Jobs = [] }
        state.Jobs.push(payload);
    },

    deleteJob(state, payload) {
        if (!state.Jobs) { state.Jobs = [] }
        state.Jobs = state.Jobs.filter(x => x.jobId != payload.jobId)
    },
    
    deleteJobs(state, payload) {
        if (!state.Jobs) { state.Jobs = [] }
        state.Jobs = state.Jobs.filter(x => x.jobId != payload.jobId);
    },

    updateJob(state, payload) {
        if (!state.Jobs) { state.Jobs = [] }
        let index = state.Jobs.findIndex(x => x.jobId == payload.jobId);
        if (index >= 0) {
            state.Jobs[index] = payload;
        }
    },

    updateJobActive(state, payload) {
        state.Job = { ...payload };
    },
}