import { createApp } from 'vue';
import App from './App.vue'
import { router } from './router/router.js';
import { store } from '@/store/store';
import moment from 'moment';
import { i18n } from './i18n/i18n-config.js'
import filters from './filters/filter';
import electronAPI from "@/electronAPI";
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/reset.css';

import './assets/main.scss';

import InputColorPicker from 'vue-native-color-picker'
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus'

// import "./plugin.js";

import { installPlugin } from './globalPlugin';
// register plugin components
import { installComponents } from './globalPluginComponents';
const app = createApp(App);

// installPlugin(app);
// installComponents(app);
electronAPI.init();
app.use(installPlugin)
app.use(installComponents)
app.use(InputColorPicker);
app.use(Antd);
app.use(moment);
app.use(ElementPlus);

app.use(router);
app.use(store);
app.use(i18n);
app.use(filters);

app.mount('#app');
document.title ="Auto Mining Control";