export default {
    updateProxies(state, payload) {
        state.Proxies = [...payload];
    },

    updateProxyPaging(state, payload) {
        state.ProxyPage = { ...payload };
        state.TotalInfo = {
            pageIndex: payload.pageIndex,
            pageSize: payload.pageSize,
            totalRecord: payload.totalRecord
        }
        if (payload && payload.data) {
            state.Proxies = payload.data;
        }
        else {
            state.Proxies = [];
        }
    },

    addProxy(state, payload) {
        if (!state.Proxies) { state.Proxies = [] }
        state.Proxies.push(payload);
    },

    deleteProxy(state, payload) {
        if (!state.Proxies) { state.Proxies = [] }
        state.Proxies.push(payload);
    },

    deleteProxies(state, payload) {
        if (!state.Proxies) { state.Proxies = [] }
        state.Proxies = state.Proxies.filter(x => x.proxyId != payload.proxyId);
    },

    updateProxy(state, payload) {
        if (!state.Proxies) { state.Proxies = [] }
        let index = state.Proxies.findIndex(x => x.proxyId == payload.proxyId);
        if (index >= 0) {
            state.Proxies[index] = payload;
        }
    },
    updateProxiesList(state, payload) {
        if (!state.Proxies) {
          state.Proxies = [];
        }
      
        payload.forEach(proxyData => {
          const index = state.Proxies.findIndex(proxy => proxy.proxyId === proxyData.deviceId);
      
          if (index >= 0) {
            // Cập nhật thông tin proxy nếu proxy đã tồn tại
            state.Proxies[index] = proxyData.proxy;
          } else {
            // Thêm proxy vào danh sách nếu không tồn tại
            //state.Proxies.push(proxyData.proxy);
          }
        });
      },
    updateProxyActive(state, payload) {
        state.Proxy = { ...payload };
    },
}