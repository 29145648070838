export default {
    userConfig: 1,
    userId: localStorage.getItem('userId'),
    userData: {},
    devices: [],
    totalOnline: 0,
    messages: [],
    deviceSelected: [],
    isUpdateReport: false,
    Loading : false
}