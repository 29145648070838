<template>
  <div v-if="isShow">
    <h-dialog :title="title" :footerDefault="footerDefault" :isShow="isShow" @okDialog="okDialog"
      @cancelDialog="cancelDialog">
      <template #content>
        <div>
          <h-combobox :data="GroupDevices" :display="'groupName'" :keyValue="'groupId'" :value="currentItem.groupId"
            @change="onClickChangeGroup"></h-combobox>
        </div>
      </template>
    </h-dialog>
  </div>
  <div v-else></div>
</template>

<script setup>
import { ModuleGroupDevice } from "@/store/module-const";
import _enum from "@/enum/enum";
import { mapGetters , mapActions } from "vuex";
</script>

<script>
export default {
  props: {
    isShow: {
      default: false,
      typeof: Boolean,
    },
    title: {
      default: "",
      typeof: String,
    },
    device: {
      default: {},
      typeof: Object,
    },
    currentItem: {
      default: {},
      typeof: Object,
    },
    rows: {
      default: [],
      typeof: Array
    }
  },
  data() {
    return {
      footerDefault: {
        cancelText: "Hủy bỏ",
        okText: "Đồng ý",
        cancelStyle: {},
        okStyle: {},
      },
    };
  },
  computed: {
    ...mapGetters(ModuleGroupDevice, ["GroupDevices"]),
  },
  async created() {
    await this.getData();
  },
  methods: {
    ...mapActions(ModuleGroupDevice, [
      'getGroupDevices'
    ]),
    async getData() {
      await this.getGroupDevices();
    },
    showDialog() { },
    cancelDialog() {
      this.$emit("cancelDialog", this.currentItem);
    },
    okDialog() {
      this.$emit("okDialog", this.currentItem);
    },
    onClickChangeGroup(value) {
      this.currentItem.groupId = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
