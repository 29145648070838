
import _enum from "@/enum/enum";
const groupsDevice = {
    data: [
        {
            groupDeviceId: '',
            groupDeviceName: 'Chưa phân nhóm',
            totalDeviceActive: 10,
            totalDeviceInActive: 1,
            isNotGroup: true
        },
        {
            groupDeviceId: '',
            groupDeviceName: 'Note 5 - root (15)',
            totalDeviceActive: 10,
            totalDeviceInActive: 1
        },
        {
            groupDeviceId: '',
            groupDeviceName: 'S8 - root (15)',
            totalDeviceActive: 10,
            totalDeviceInActive: 5
        },
        {
            groupDeviceId: '',
            groupDeviceName: 'S8 - No root (15)',
            totalDeviceActive: 10,
            totalDeviceInActive: 5
        }
    ],
    totalDeviceInActive: 12,
    totalDeviceActive: 40

};


const logSystems = [
    {
        id: 1,
        logName: 'G955F-01 kết nối',
        status: _enum.LogSystemStatus.Connect,
        dateTime: Date.now()
    },
    {
        id: 2,
        logName: 'G955F-01 ngắt kết nối',
        status: _enum.LogSystemStatus.Disconnect,
        dateTime: Date.now()
    },
    {
        id: 3,
        logName: 'G955F-01 Hoàn thành Job',
        status: _enum.LogSystemStatus.JobSuccess,
        dateTime: Date.now()
    },
    {
        id: 4,
        logName: 'G955F-01 gặp lỗi tạm dừng',
        status: _enum.LogSystemStatus.PauseError,
        dateTime: Date.now()
    },
    {
        id: 5,
        logName: 'G955F-01 kết nối',
        status: _enum.LogSystemStatus.Connect,
        dateTime: Date.now()
    },
    {
        id: 6,
        logName: 'G955F-01 ngắt kết nối',
        status: _enum.LogSystemStatus.Disconnect,
        dateTime: Date.now()
    },
    {
        id: 7,
        logName: 'G955F-01 Hoàn thành Job',
        status: _enum.LogSystemStatus.JobSuccess,
        dateTime: Date.now()
    },
    {
        id: 8,
        logName: 'G955F-01 gặp lỗi tạm dừng',
        status: _enum.LogSystemStatus.PauseError,
        dateTime: Date.now()
    }
]


const tasksProcess = [
    {
        taskId: '1',
        taskName: 'Ngồi chơi',
        number: 0,
        process: 50,
        status: _enum.TaskProcessStatus.Pause
    },
    {
        taskId: '2',
        taskName: 'Lướt view YT theo danh sách',
        number: 10,
        process: 30,
        status: _enum.TaskProcessStatus.Running
    },
    {
        taskId: '3',
        taskName: 'Lươt view YT theo đề xuất',
        number: 5,
        process: 90,
        status: _enum.TaskProcessStatus.Pause
    },
    {
        taskId: '4',
        taskName: 'Tiktok lướt',
        number: 3,
        process: 60,
        status: _enum.TaskProcessStatus.Running
    }
]

const devices = [
    {
        "_id": "64cd324e821dffc9f5a2813c",
        "deviceId": "ce11171be82d922703",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 2002000,
        "cpumhzmax": 0,
        "createDate": "2023-08-04T17:15:58.586Z",
        "hash": 3952.89,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:12.966Z",
        "localIp": "10.0.0.232",
        "root": true,
        "sshUser": "u0_a97",
        "startDate": "2023-08-04T17:15:58.586Z",
        "status": 1,
        "tempe": 82,
        "userId": "EpvOjwg1M",
        "deviceName": "S8PL-P4",
        "lastInOut": "2023-10-01T05:45:02.055Z",
        "miningCoin": "VRSC",
        "model": "SM-G955F"
    },
    {
        "_id": "64db9868ccff3f10a66c4f1c",
        "deviceId": "ce04171408f5101c05",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1937000,
        "cpumhzmax": 0,
        "createDate": "2023-08-15T15:23:20.557Z",
        "hash": 3927.49,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:12.120Z",
        "lastInOut": "2023-10-03T01:15:31.994Z",
        "localIp": "10.0.0.145",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-08-15T15:23:20.557Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "model": "SM-G950F",
        "deviceName": "S81-3"
    },
    {
        "_id": "64d878bffad0d606a9ad2ffa",
        "deviceId": "0217129b70d23b02",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T06:31:27.265Z",
        "hash": 3001.33,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:12.069Z",
        "lastInOut": "2023-09-29T17:35:21.844Z",
        "localIp": "10.0.2.81",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T06:31:27.265Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "model": "SM-G920F",
        "deviceName": "A01-4"
    },
    {
        "_id": "64f228474cbeb902902b0239",
        "deviceId": "0915f9e378164004",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-09-01T18:07:03.263Z",
        "hash": 2968.12,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:12.063Z",
        "lastInOut": "2023-09-29T17:35:00.089Z",
        "localIp": "10.0.0.97",
        "model": "SM-N920V",
        "root": false,
        "sshUser": "u0_a219",
        "startDate": "2023-09-01T18:07:03.263Z",
        "status": 1,
        "tempe": 74,
        "userId": "EpvOjwg1M",
        "deviceName": "V01-4"
    },
    {
        "_id": "64d3c7a130382dfe85999d6c",
        "deviceId": "83f152494c5a3737",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-09T17:06:41.770Z",
        "hash": 2948.3,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:11.402Z",
        "lastInOut": "2023-09-29T17:35:25.670Z",
        "localIp": "10.0.2.233",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-09T17:06:41.771Z",
        "status": 1,
        "tempe": 81,
        "userId": "EpvOjwg1M",
        "deviceName": "N5-16",
        "miningCoin": "VRSC"
    },
    {
        "cpumhzmax": 0,
        "_id": "64979943e05a98c1aad0c504",
        "deviceId": "0815f82020662002",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "cpuUse": 100,
        "createDate": "2023-06-25T01:32:51.602Z",
        "lastCheck": "2023-10-03T04:23:11.297Z",
        "localIp": "10.0.2.60",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-06-25T01:32:51.602Z",
        "status": 1,
        "tempe": 82,
        "userId": "EpvOjwg1M",
        "deviceName": "N5-9",
        "cpumhz": 1300000,
        "hash": 2981.86,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:12.406Z",
        "model": "SM-G920F"
    },
    {
        "cpumhzmax": 0,
        "_id": "646589f35bbd16aa5010a972",
        "deviceId": "0c15021820de8304",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "cpuUse": 100,
        "createDate": "2023-05-18T02:14:11.694Z",
        "deviceName": "N5-6",
        "lastCheck": "2023-10-03T04:23:10.747Z",
        "localIp": "10.0.2.140",
        "model": "SM-G920F",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-05-18T02:14:11.694Z",
        "status": 1,
        "userId": "EpvOjwg1M",
        "isMining": true,
        "miningCoin": "VRSC",
        "cpumhz": 1704000,
        "hash": 3115.61,
        "tempe": 77,
        "lastInOut": "2023-09-29T17:35:08.222Z"
    },
    {
        "_id": "64dbb44eccff3f10a68315fd",
        "deviceId": "84314f594f4d4841",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-15T17:22:22.817Z",
        "hash": 3093.23,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:10.711Z",
        "lastInOut": "2023-09-29T17:35:32.235Z",
        "localIp": "10.0.2.147",
        "model": "SM-G920F",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-15T17:22:22.817Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A03-3"
    },
    {
        "_id": "64cbda7399186068302f03d0",
        "deviceId": "05157df5bce4a829",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-03T16:48:51.557Z",
        "hash": 2999.8,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:09.935Z",
        "localIp": "10.0.2.195",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-03T16:48:51.557Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A03-5",
        "lastInOut": "2023-09-29T17:35:18.877Z",
        "miningCoin": "VRSC",
        "model": "SM-G920F"
    },
    {
        "_id": "64d895a4fad0d606a9c31ccf",
        "deviceId": "84315249364c4848",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T08:34:44.161Z",
        "hash": 3154.24,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:09.922Z",
        "lastInOut": "2023-09-29T17:35:23.948Z",
        "localIp": "10.0.2.117",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T08:34:44.161Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A02-3",
        "model": "SM-G920F"
    },
    {
        "cpumhzmax": 0,
        "_id": "64658bb45bbd16aa5010aa2d",
        "deviceId": "0715f7f125373905",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "cpuUse": 100,
        "createDate": "2023-05-18T02:21:40.652Z",
        "deviceName": "N5-8",
        "lastCheck": "2023-10-03T04:23:09.764Z",
        "localIp": "10.0.2.186",
        "model": "SM-G920F",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-05-18T02:21:40.652Z",
        "status": 1,
        "userId": "EpvOjwg1M",
        "tempe": 80,
        "hash": 3004.17,
        "cpumhz": 1300000,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:09.983Z"
    },
    {
        "_id": "64b211a992fb88ccc86d1e1f",
        "deviceId": "ce081718cadc863e05",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 2158000,
        "cpumhzmax": 0,
        "createDate": "2023-07-15T03:25:28.955Z",
        "hash": 4137.11,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:09.239Z",
        "localIp": "10.0.0.139",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-07-15T03:25:28.955Z",
        "status": 1,
        "tempe": 80,
        "userId": "EpvOjwg1M",
        "deviceName": "S8-P5",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:37.507Z",
        "model": "SM-G950F"
    },
    {
        "_id": "64b1f5a292fb88ccc8550e3d",
        "deviceId": "rljNhKB1Z",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 0,
        "cpuUse": 0,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-07-15T01:25:54.844Z",
        "hash": 3360.39,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:08.539Z",
        "localIp": "10.0.0.10",
        "root": false,
        "sshUser": "u0_a244",
        "startDate": "2023-07-15T01:25:54.844Z",
        "status": 1,
        "tempe": 45.3,
        "userId": "EpvOjwg1M",
        "deviceName": "S8-J1PH",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:17.445Z",
        "model": "SC-02J"
    },
    {
        "_id": "64d871eafad0d606a9a8014c",
        "deviceId": "0815f889df230905",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T06:02:18.772Z",
        "hash": 3012.85,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:08.470Z",
        "lastInOut": "2023-09-29T17:35:21.264Z",
        "localIp": "10.0.2.235",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T06:02:18.772Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A01-3",
        "model": "SM-G920F"
    },
    {
        "cpumhzmax": 0,
        "_id": "64634330956e181534c20b33",
        "deviceId": "0815f819e7d41705",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "cpuUse": 100,
        "createDate": "2023-05-16T08:47:45.485Z",
        "deviceName": "N5-1",
        "lastCheck": "2023-10-03T04:23:08.408Z",
        "localIp": "10.0.2.47",
        "model": "SM-G920F",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-05-16T08:47:45.485Z",
        "status": 1,
        "userId": "EpvOjwg1M",
        "tempe": 80,
        "hash": 2997.98,
        "cpumhz": 1300000,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:10.907Z"
    },
    {
        "_id": "64cd376f821dffc9f5a77e72",
        "deviceId": "0815f85517b23c03",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1704000,
        "cpumhzmax": 0,
        "createDate": "2023-08-04T17:37:50.953Z",
        "hash": 3042.79,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:08.355Z",
        "localIp": "10.0.2.48",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-04T17:37:50.953Z",
        "status": 1,
        "tempe": 78,
        "userId": "EpvOjwg1M",
        "deviceName": "N5-14",
        "lastInOut": "2023-09-29T17:35:21.212Z",
        "miningCoin": "VRSC",
        "model": "SM-G920F"
    },
    {
        "cpumhzmax": 0,
        "_id": "647b74105a11957e087bbef2",
        "deviceId": "0815f88536660805",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "createDate": "2023-06-03T17:10:40.408Z",
        "lastCheck": "2023-10-03T04:23:07.928Z",
        "localIp": "10.0.2.115",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-06-03T17:10:40.408Z",
        "status": 1,
        "userId": "EpvOjwg1M",
        "cpuUse": 100,
        "tempe": 83,
        "deviceName": "N5-4",
        "hash": 2983.3,
        "cpumhz": 1300000,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:12.067Z",
        "model": "SM-G920F"
    },
    {
        "_id": "64cd46c0821dffc9f5b67956",
        "deviceId": "0915f93535691805",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-04T18:43:12.260Z",
        "hash": 2891.24,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:07.656Z",
        "localIp": "10.0.2.130",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-04T18:43:12.260Z",
        "status": 1,
        "tempe": 84,
        "userId": "EpvOjwg1M",
        "deviceName": "N5-15",
        "lastInOut": "2023-09-29T17:35:27.469Z",
        "miningCoin": "VRSC",
        "model": "SM-G920F"
    },
    {
        "_id": "64ea3e7ece6819834137798b",
        "deviceId": "0715f7daee420238",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-08-26T18:03:42.551Z",
        "hash": 2890.62,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:06.845Z",
        "lastInOut": "2023-09-29T17:35:09.655Z",
        "localIp": "10.0.0.158",
        "model": "SM-N920V",
        "root": false,
        "sshUser": "u0_a220",
        "startDate": "2023-08-26T18:03:42.551Z",
        "status": 1,
        "tempe": 75,
        "userId": "EpvOjwg1M",
        "deviceName": "V01-1"
    },
    {
        "_id": "65145d8cbc54db3e960df922",
        "deviceId": "ZknmVe23W",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 0,
        "cpuUse": 0,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-09-27T16:51:24.504Z",
        "hash": 3568.5,
        "isMining": false,
        "lastCheck": "2023-10-03T04:23:06.697Z",
        "lastInOut": "2023-10-01T17:33:36.847Z",
        "localIp": "10.0.0.15",
        "model": "SM-G9550",
        "root": false,
        "sshUser": "u0_a218",
        "startDate": "2023-09-27T16:51:24.504Z",
        "status": 1,
        "tempe": 39.5,
        "userId": "EpvOjwg1M",
        "deviceName": "G955U-01"
    },
    {
        "cpumhzmax": 0,
        "_id": "647b73a35a11957e087bbea5",
        "deviceId": "0815f85390b21804",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "createDate": "2023-06-03T17:08:51.434Z",
        "lastCheck": "2023-10-03T04:23:06.543Z",
        "localIp": "10.0.2.123",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-06-03T17:08:51.434Z",
        "status": 1,
        "userId": "EpvOjwg1M",
        "cpuUse": 99,
        "tempe": 79,
        "deviceName": "N5-3",
        "hash": 3002.52,
        "cpumhz": 1300000,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:09.010Z",
        "model": "SM-G920F"
    },
    {
        "_id": "64c54f31bb3845fd9adf0468",
        "deviceId": "ce08171875936c730b",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 2002000,
        "cpumhzmax": 0,
        "createDate": "2023-07-29T17:41:05.351Z",
        "hash": 4002.67,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:06.526Z",
        "localIp": "10.0.0.121",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-07-29T17:41:05.351Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "S8-P1",
        "lastInOut": "2023-09-29T17:34:28.200Z",
        "miningCoin": "VRSC",
        "model": "SM-G950F"
    },
    {
        "_id": "64f21e7b4cbeb90290145e39",
        "deviceId": "05157df55ce0341b",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-09-01T17:25:15.694Z",
        "hash": 2981.05,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:05.865Z",
        "lastInOut": "2023-09-29T17:35:00.474Z",
        "localIp": "10.0.0.81",
        "root": false,
        "sshUser": "u0_a188",
        "startDate": "2023-09-01T17:25:15.694Z",
        "status": 1,
        "tempe": 75,
        "userId": "EpvOjwg1M",
        "model": "SM-N9208",
        "deviceName": "V01-3-9208"
    },
    {
        "_id": "64ee257c3688ac8e07c52e22",
        "deviceId": "0915f9f22ddc0804",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 4,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-29T17:06:04.065Z",
        "hash": 2995.45,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:05.225Z",
        "lastInOut": "2023-09-29T17:35:21.083Z",
        "localIp": "10.0.2.28",
        "model": "SM-N920P",
        "root": true,
        "sshUser": "u0_a181",
        "startDate": "2023-08-29T17:06:04.066Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "P01-2"
    },
    {
        "_id": "64d881d2fad0d606a9b40803",
        "deviceId": "85b6473739464349",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T07:10:10.805Z",
        "hash": 3124.92,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:05.095Z",
        "lastInOut": "2023-09-29T17:35:22.690Z",
        "localIp": "10.0.2.141",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T07:10:10.805Z",
        "status": 1,
        "tempe": 77,
        "userId": "EpvOjwg1M",
        "model": "SM-G920F",
        "deviceName": "A01-5"
    },
    {
        "cpumhzmax": 0,
        "_id": "647b737b5a11957e087bbe88",
        "deviceId": "0715f731c5293338",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "createDate": "2023-06-03T17:08:11.701Z",
        "lastCheck": "2023-10-03T04:23:05.047Z",
        "localIp": "10.0.2.126",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-06-03T17:08:11.702Z",
        "status": 1,
        "userId": "EpvOjwg1M",
        "cpuUse": 100,
        "tempe": 79,
        "deviceName": "N5-2",
        "hash": 3033.68,
        "cpumhz": 1300000,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:09.960Z",
        "model": "SM-G920F"
    },
    {
        "cpumhzmax": 0,
        "_id": "64a77556e05a98c1aafbb923",
        "deviceId": "ce031713dc65290702",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "createDate": "2023-07-07T02:15:50.259Z",
        "lastCheck": "2023-10-03T04:23:04.955Z",
        "localIp": "10.0.0.197",
        "root": true,
        "sshUser": "u0_a97",
        "startDate": "2023-07-07T02:15:50.259Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "cpumhz": 1937000,
        "hash": 3838.51,
        "deviceName": "S8pl-p2",
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:34.010Z",
        "model": "SM-G955F"
    },
    {
        "_id": "64f22db5bf9772641c22735b",
        "deviceId": "1115fbed98340b05",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-09-01T18:30:13.490Z",
        "hash": 2949.01,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:04.515Z",
        "lastInOut": "2023-09-29T17:35:01.198Z",
        "localIp": "10.0.0.215",
        "model": "SM-N920V",
        "root": false,
        "sshUser": "u0_a219",
        "startDate": "2023-09-01T18:30:13.490Z",
        "status": 1,
        "tempe": 72,
        "userId": "EpvOjwg1M",
        "deviceName": "V01-5"
    },
    {
        "_id": "64b23cb592fb88ccc892609d",
        "deviceId": "ce03171373b010a50c",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 2002000,
        "cpumhzmax": 0,
        "createDate": "2023-07-15T06:29:09.859Z",
        "hash": 3943.25,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:03.894Z",
        "localIp": "10.0.0.90",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-07-15T06:29:09.859Z",
        "status": 1,
        "tempe": 81,
        "userId": "EpvOjwg1M",
        "deviceName": "s8-P3",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:36.950Z",
        "model": "SM-G950F"
    },
    {
        "_id": "64b6b64e2d6efa454df9ba5d",
        "deviceId": "ce0217129d69ba3505",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1937000,
        "cpumhzmax": 0,
        "createDate": "2023-07-18T15:57:02.300Z",
        "hash": 3812.62,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:03.707Z",
        "localIp": "10.0.0.71",
        "root": true,
        "sshUser": "u0_a97",
        "startDate": "2023-07-18T15:57:02.300Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "S8PL-P3",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:43.859Z",
        "model": "SM-G955F"
    },
    {
        "_id": "64c49778bb3845fd9a46ac1b",
        "deviceId": "02157df2aa170014",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-07-29T04:37:12.830Z",
        "hash": 2870.87,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:03.211Z",
        "localIp": "10.0.2.91",
        "root": true,
        "sshUser": "u0_a86",
        "startDate": "2023-07-29T04:37:12.830Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "S6E-1",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:41.595Z",
        "model": "SM-G925F"
    },
    {
        "_id": "64b23a6392fb88ccc8905b4d",
        "deviceId": "ce081718e8ca4b1f01",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 2158000,
        "cpumhzmax": 0,
        "createDate": "2023-07-15T06:19:15.523Z",
        "hash": 4127.93,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:01.827Z",
        "localIp": "10.0.0.130",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-07-15T06:19:15.523Z",
        "status": 1,
        "tempe": 82,
        "userId": "EpvOjwg1M",
        "deviceName": "S8-P4",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:38.811Z",
        "model": "SM-G950F"
    },
    {
        "_id": "64d86247fad0d606a99c2ef2",
        "deviceId": "02160228cb8ab3c7",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1704000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T04:55:35.399Z",
        "hash": 3027.89,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:01.155Z",
        "lastInOut": "2023-09-29T17:35:20.803Z",
        "localIp": "10.0.2.253",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T04:55:35.399Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A01-2",
        "model": "SM-G920F"
    },
    {
        "_id": "64ce9340cb0d089c39b7c8c0",
        "deviceId": "ce0817181a5ec0260c",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1807000,
        "cpumhzmax": 0,
        "createDate": "2023-08-05T18:21:52.107Z",
        "hash": 3717.59,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:00.548Z",
        "localIp": "10.0.0.89",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-08-05T18:21:52.107Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "S81-2",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:38.791Z"
    },
    {
        "_id": "64c4cd9dbb3845fd9a73b4bc",
        "deviceId": "031603ca8ad21601",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1000000,
        "cpumhzmax": 0,
        "createDate": "2023-07-29T08:28:13.433Z",
        "hash": 2586.47,
        "isMining": true,
        "lastCheck": "2023-10-03T04:23:00.197Z",
        "localIp": "10.0.2.107",
        "root": true,
        "sshUser": "u0_a86",
        "startDate": "2023-07-29T08:28:13.433Z",
        "status": 1,
        "tempe": 85,
        "userId": "EpvOjwg1M",
        "deviceName": "S6-2",
        "lastInOut": "2023-09-29T17:34:40.030Z",
        "miningCoin": "VRSC",
        "model": "SM-G920F"
    },
    {
        "_id": "64ee28ba3688ac8e07cb711a",
        "deviceId": "0715f75408900a38",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 4,
        "cpuUse": 100,
        "cpumhz": 1500000,
        "cpumhzmax": 0,
        "createDate": "2023-08-29T17:19:54.203Z",
        "hash": 3241.05,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:59.777Z",
        "lastInOut": "2023-09-29T17:35:19.579Z",
        "localIp": "10.0.2.11",
        "model": "SM-N920P",
        "root": true,
        "sshUser": "u0_a181",
        "startDate": "2023-08-29T17:19:54.203Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "P01-3"
    },
    {
        "cpumhzmax": 0,
        "_id": "646c8b78a005f5e60e360bdc",
        "deviceId": "04157df4e5878619",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "createDate": "2023-05-23T09:46:32.942Z",
        "lastCheck": "2023-10-03T04:22:59.580Z",
        "localIp": "10.0.2.187",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-05-23T09:46:32.942Z",
        "status": 1,
        "userId": "EpvOjwg1M",
        "cpuUse": 100,
        "deviceName": "N5-7",
        "isMining": true,
        "miningCoin": "VRSC",
        "cpumhz": 1300000,
        "hash": 3077.38,
        "tempe": 80,
        "lastInOut": "2023-09-29T17:35:09.674Z",
        "model": "SM-G920F"
    },
    {
        "_id": "64eb856ca508ae9338a311c0",
        "deviceId": "0416041495e33402",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 4,
        "cpuUse": 100,
        "cpumhz": 1500000,
        "cpumhzmax": 0,
        "createDate": "2023-08-27T17:18:36.610Z",
        "hash": 3183.03,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:59.325Z",
        "lastInOut": "2023-09-29T17:36:19.663Z",
        "localIp": "10.0.0.21",
        "model": "SM-N920P",
        "root": true,
        "sshUser": "u0_a181",
        "startDate": "2023-08-27T17:18:36.610Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "P01-1",
        "miningCoin": "VRSC"
    },
    {
        "_id": "64c55893bb3845fd9ae6e399",
        "deviceId": "ce06171630ad160b01",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 2002000,
        "cpumhzmax": 0,
        "createDate": "2023-07-29T18:21:07.802Z",
        "hash": 3958.67,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:58.604Z",
        "localIp": "10.0.0.58",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-07-29T18:21:07.802Z",
        "status": 1,
        "tempe": 80,
        "userId": "EpvOjwg1M",
        "deviceName": "S8-P2",
        "lastInOut": "2023-09-29T17:34:32.932Z",
        "miningCoin": "VRSC",
        "model": "SM-G950F"
    },
    {
        "_id": "64d88f11fad0d606a9be1523",
        "deviceId": "0515394133313134",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1704000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T08:06:41.611Z",
        "hash": 3057.1,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:57.831Z",
        "lastInOut": "2023-10-02T21:59:12.908Z",
        "localIp": "10.0.2.51",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T08:06:41.611Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A02-1",
        "model": "SM-G920F"
    },
    {
        "_id": "64cd2676821dffc9f59866aa",
        "deviceId": "11160b5ae18e3702",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-04T16:25:26.379Z",
        "hash": 2989.13,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:57.574Z",
        "localIp": "10.0.2.156",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-04T16:25:26.379Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A03-4",
        "lastInOut": "2023-09-29T17:35:21.104Z",
        "miningCoin": "VRSC",
        "model": "SM-G920F"
    },
    {
        "_id": "64cd2d66821dffc9f59dcff4",
        "deviceId": "0715f75a81533738",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1200000,
        "cpumhzmax": 0,
        "createDate": "2023-08-04T16:55:01.983Z",
        "hash": 2718.04,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:57.050Z",
        "localIp": "10.0.2.22",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-04T16:55:01.983Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "N5-13",
        "lastInOut": "2023-09-29T17:35:21.529Z",
        "miningCoin": "VRSC",
        "model": "SM-G920F"
    },
    {
        "_id": "64d8a5c7fad0d606a9cf6387",
        "deviceId": "05157df5aa315f25",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T09:43:35.723Z",
        "hash": 3037.18,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:56.861Z",
        "lastInOut": "2023-09-29T17:35:20.999Z",
        "localIp": "10.0.2.133",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T09:43:35.723Z",
        "status": 1,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A02-5",
        "model": "SM-G920F"
    },
    {
        "cpumhzmax": 0,
        "_id": "6461136f76a196610581ad74",
        "deviceId": "03157df38c35550b",
        "deviceName": "S6-1",
        "userId": "EpvOjwg1M",
        "adbWifi": false,
        "root": true,
        "status": 1,
        "createDate": "2023-05-14T16:59:27.344Z",
        "startDate": "2023-05-14T16:59:27.344Z",
        "lastCheck": "2023-10-03T04:22:56.839Z",
        "__v": 0,
        "cpuUse": 100,
        "localIp": "10.0.2.55",
        "model": "SM-G920F",
        "sshUser": "u0_a86",
        "cpuCores": 8,
        "isMining": true,
        "miningCoin": "VRSC",
        "cpumhz": 800000,
        "hash": 2353.72,
        "tempe": 83,
        "lastInOut": "2023-09-29T17:34:42.408Z"
    },
    {
        "_id": "64aedd75e05a98c1aae506a5",
        "deviceId": "ce061716d89be33f04",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1937000,
        "cpumhzmax": 0,
        "createDate": "2023-07-12T17:05:57.620Z",
        "hash": 3777.39,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:55.943Z",
        "localIp": "10.0.0.50",
        "root": true,
        "sshUser": "u0_a97",
        "startDate": "2023-07-12T17:05:57.620Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "S8PL-P5",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:37.151Z",
        "model": "SM-G955F"
    },
    {
        "_id": "64d89afefad0d606a9c72b0d",
        "deviceId": "05157df5d9a36216",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T08:57:34.313Z",
        "hash": 3053.72,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:55.904Z",
        "lastInOut": "2023-09-29T17:35:23.160Z",
        "localIp": "10.0.2.103",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T08:57:34.313Z",
        "status": 1,
        "tempe": 78,
        "userId": "EpvOjwg1M",
        "deviceName": "A02-4",
        "model": "SM-G920F"
    },
    {
        "_id": "64d8929efad0d606a9c0cb65",
        "deviceId": "0515394546323937",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1704000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T08:21:50.428Z",
        "hash": 3129.36,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:55.815Z",
        "lastInOut": "2023-10-01T21:59:50.244Z",
        "localIp": "10.0.2.242",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T08:21:50.428Z",
        "status": 1,
        "tempe": 78,
        "userId": "EpvOjwg1M",
        "deviceName": "A02-2",
        "model": "SM-G920F"
    },
    {
        "_id": "64b2049c92fb88ccc861f1da",
        "deviceId": "ce10171a805be51702",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1937000,
        "cpumhzmax": 0,
        "createDate": "2023-07-15T02:29:48.181Z",
        "hash": 3721.06,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:55.496Z",
        "localIp": "10.0.0.156",
        "root": true,
        "sshUser": "u0_a83",
        "startDate": "2023-07-15T02:29:48.181Z",
        "status": 1,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "S81-4",
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:34:20.769Z",
        "model": "SM-G950F"
    },
    {
        "_id": "6511ba25bc54db3e96ad1315",
        "deviceId": "988820365050305957",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 99,
        "cpumhz": 2361600,
        "cpumhzmax": 0,
        "createDate": "2023-09-25T16:49:41.663Z",
        "hash": 4462.47,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:54.938Z",
        "lastInOut": "2023-09-29T17:35:12.382Z",
        "localIp": "10.0.0.36",
        "root": true,
        "sshUser": "u0_a180",
        "startDate": "2023-09-25T16:49:41.663Z",
        "status": 1,
        "tempe": 54,
        "userId": "EpvOjwg1M",
        "model": "SM-G950U",
        "deviceName": "s8u-01"
    },
    {
        "_id": "64d66918e4abeb99d37aa3e9",
        "deviceId": "0915f94b78822005",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-11T17:00:08.696Z",
        "hash": 3069.27,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:54.418Z",
        "lastInOut": "2023-09-30T22:03:28.469Z",
        "localIp": "10.0.2.157",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-11T17:00:08.696Z",
        "status": 1,
        "tempe": 78,
        "userId": "EpvOjwg1M",
        "deviceName": "A01-1",
        "model": "SM-G920F"
    },
    {
        "_id": "64f21ae04cbeb902900bf610",
        "deviceId": "0316039dae5b0b01",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-09-01T17:09:52.843Z",
        "hash": 2986.21,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:54.246Z",
        "lastInOut": "2023-09-29T17:34:55.186Z",
        "localIp": "10.0.0.164",
        "model": "SM-N9208",
        "root": false,
        "sshUser": "u0_a189",
        "startDate": "2023-09-01T17:09:52.843Z",
        "status": 1,
        "tempe": 73,
        "userId": "EpvOjwg1M",
        "deviceName": "V01-2-9208"
    },
    {
        "_id": "64db9a33ccff3f10a66dbcd5",
        "deviceId": "ce10171ab20f292c04",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 99,
        "cpumhz": 1807000,
        "cpumhzmax": 0,
        "createDate": "2023-08-15T15:30:59.386Z",
        "hash": 3795.32,
        "isMining": true,
        "lastCheck": "2023-10-03T04:22:53.695Z",
        "lastInOut": "2023-09-29T17:34:34.386Z",
        "localIp": "10.0.0.95",
        "root": true,
        "sshUser": "u0_a4",
        "startDate": "2023-08-15T15:30:59.386Z",
        "status": 1,
        "tempe": 82,
        "userId": "EpvOjwg1M",
        "model": "SM-G950F",
        "deviceName": "S81-1"
    },
    {
        "cpumhzmax": 0,
        "_id": "64979c07e05a98c1aad2796b",
        "deviceId": "081608552bd51404",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "cpuUse": 100,
        "createDate": "2023-06-25T01:44:39.409Z",
        "lastCheck": "2023-10-03T04:22:53.363Z",
        "localIp": "10.0.2.76",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-06-25T01:44:39.409Z",
        "status": 1,
        "tempe": 81,
        "userId": "EpvOjwg1M",
        "deviceName": "N5-10",
        "hash": 3133.05,
        "cpumhz": 1300000,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:35:12.060Z",
        "model": "SM-G920F"
    },
    {
        "cpumhzmax": 0,
        "_id": "647b70375a11957e087bbc8b",
        "deviceId": "ce0317135dd670180c",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "createDate": "2023-06-03T16:54:15.291Z",
        "lastCheck": "2023-09-29T17:32:55.643Z",
        "localIp": "10.0.0.150",
        "root": true,
        "sshUser": "u0_a83",
        "startDate": "2023-06-03T16:54:15.291Z",
        "status": 0,
        "userId": "EpvOjwg1M",
        "cpuUse": 100,
        "deviceName": "S81-5",
        "tempe": 83,
        "cpumhz": 1937000,
        "hash": 3767.28,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-09-29T17:33:55.643Z",
        "model": "SM-G950F"
    },
    {
        "_id": "64d90e4046de94e8dc319443",
        "deviceId": "0417143be0c40f04",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-13T17:09:20.153Z",
        "hash": 3056.53,
        "isMining": true,
        "lastCheck": "2023-09-25T16:09:01.403Z",
        "lastInOut": "2023-09-25T16:10:01.408Z",
        "localIp": "10.0.2.89",
        "root": true,
        "sshUser": "u0_a129",
        "startDate": "2023-08-13T17:09:20.153Z",
        "status": 0,
        "tempe": 80,
        "userId": "EpvOjwg1M",
        "deviceName": "A03-1-loi",
        "model": "SM-G920F"
    },
    {
        "_id": "64dbb11bccff3f10a68068bc",
        "deviceId": "05157df51bb2d219",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1300000,
        "cpumhzmax": 0,
        "createDate": "2023-08-15T17:08:43.465Z",
        "hash": 2988.43,
        "isMining": true,
        "lastCheck": "2023-09-10T19:54:12.848Z",
        "lastInOut": "2023-09-10T19:55:12.849Z",
        "localIp": "10.0.2.185",
        "model": "SM-G920F",
        "root": true,
        "sshUser": "u0_a130",
        "startDate": "2023-08-15T17:08:43.465Z",
        "status": 0,
        "tempe": 79,
        "userId": "EpvOjwg1M",
        "deviceName": "A03-2"
    },
    {
        "_id": "64da5094ce9a7a7db3c0fe11",
        "deviceId": "ce11160b6bd6943402",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 1,
        "cpumhz": 1352000,
        "cpumhzmax": 0,
        "createDate": "2023-08-14T16:04:35.472Z",
        "hash": 1061.78,
        "isMining": false,
        "lastCheck": "2023-08-14T16:37:34.716Z",
        "lastInOut": "2023-08-14T16:38:34.720Z",
        "localIp": "10.0.0.182",
        "root": true,
        "sshUser": "u0_a5",
        "startDate": "2023-08-14T16:04:35.472Z",
        "status": 0,
        "tempe": 32.5,
        "userId": "EpvOjwg1M",
        "deviceName": "s7"
    },
    {
        "_id": "64da479d46de94e8dc28d2ef",
        "deviceId": "ce0316039183ab0904",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 0,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-08-14T15:26:21.564Z",
        "hash": 0,
        "isMining": true,
        "lastCheck": "2023-08-14T15:26:21.564Z",
        "lastInOut": "2023-08-14T15:27:21.464Z",
        "localIp": "10.0.0.35",
        "root": true,
        "sshUser": "u0_a7",
        "startDate": "2023-08-14T15:26:21.564Z",
        "status": 0,
        "tempe": 0,
        "userId": "EpvOjwg1M",
        "deviceName": "khongbiet"
    },
    {
        "cpumhzmax": 0,
        "_id": "6463ab69956e18153418052e",
        "deviceId": "83f144364a57474e",
        "__v": 0,
        "adbWifi": true,
        "cpuCores": 8,
        "cpuUse": 100,
        "createDate": "2023-05-16T16:12:27.015Z",
        "deviceName": "N5-5",
        "lastCheck": "2023-08-13T14:24:53.333Z",
        "localIp": "10.0.0.36",
        "model": "SM-G920F",
        "root": true,
        "sshUser": "u0_a2",
        "startDate": "2023-05-16T16:12:27.015Z",
        "status": 0,
        "userId": "EpvOjwg1M",
        "tempe": 78,
        "hash": 3016.73,
        "cpumhz": 1300000,
        "isMining": true,
        "miningCoin": "VRSC",
        "lastInOut": "2023-08-13T14:25:53.336Z"
    },
    {
        "_id": "64b95ba35409072b8e36afa8",
        "deviceId": "ce041714a307443401",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 2002000,
        "cpumhzmax": 0,
        "createDate": "2023-07-20T16:06:59.864Z",
        "hash": 3861.28,
        "isMining": true,
        "lastCheck": "2023-08-10T12:58:19.961Z",
        "localIp": "10.0.0.159",
        "root": true,
        "sshUser": "u0_a97",
        "startDate": "2023-07-20T16:06:59.864Z",
        "status": 0,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "DaBan-S8pl1",
        "miningCoin": "VRSC",
        "lastInOut": "2023-08-10T12:59:19.961Z",
        "model": "SM-G955F"
    },
    {
        "_id": "64cd28ca821dffc9f59a17d1",
        "deviceId": "ce031713da90270b01",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 8,
        "cpuUse": 100,
        "cpumhz": 1807000,
        "cpumhzmax": 0,
        "createDate": "2023-08-04T16:35:22.020Z",
        "hash": 3737.23,
        "isMining": true,
        "lastCheck": "2023-08-10T12:58:08.599Z",
        "localIp": "10.0.0.84",
        "root": true,
        "sshUser": "u0_a97",
        "startDate": "2023-08-04T16:35:22.020Z",
        "status": 0,
        "tempe": 83,
        "userId": "EpvOjwg1M",
        "deviceName": "DaBanS8PL-P1",
        "lastInOut": "2023-08-10T12:59:08.604Z",
        "miningCoin": "VRSC"
    },
    {
        "_id": "64cfc509ef47b3e1c4134639",
        "deviceId": "ZT0",
        "__v": 0,
        "adbWifi": false,
        "cpuCores": 0,
        "cpuUse": 0,
        "cpumhz": 0,
        "cpumhzmax": 0,
        "createDate": "2023-08-06T16:06:33.390Z",
        "hash": 500.67,
        "isMining": true,
        "lastCheck": "2023-08-06T16:16:56.500Z",
        "localIp": "10.0.0.195",
        "root": false,
        "sshUser": "u0_a200",
        "startDate": "2023-08-06T16:06:33.390Z",
        "status": 0,
        "tempe": 65.6,
        "userId": "EpvOjwg1M",
        "deviceName": "P4-S8PL1",
        "miningCoin": "VRSC",
        "lastInOut": "2023-10-03T04:23:13.444Z"
    }
];

export default { groupsDevice, logSystems, tasksProcess, devices }
