import actions from './actions-proxy.js'
import mutations from './mutations-proxy.js'
import getters from './getters-proxy.js'
import state from './state-proxy.js'

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}