import { ModuleDevice } from '@/store/module-const'
import { store } from '../store/store'
export default {
    isInit:true,
    viewDevices: function(payload){
        if(typeof electronAPI !== 'undefined' && electronAPI){
            const payloadCopy = JSON.parse(JSON.stringify(payload)); 
            electronAPI.sendToMain('message', {command:"View-Devices",payload:payloadCopy});
        }
    },
    viewPhone: function(device){
        if(typeof electronAPI !== 'undefined' && electronAPI){
            const params = {
                deviceIp:device.localIp,
                deviceName:`${device.deviceName||device.deviceId} | ${device.model}`
            }
            electronAPI.sendToMain('message', {command:"VIEW-PHONE",params:params});
        }
    },
    init: function(){
        if(typeof electronAPI !== 'undefined' && electronAPI && this.isInit){
            this.isInit =false;
            electronAPI.receiveFromMain('message', (data) => {
                switch(data.command){
                    case "ADB_CONNECT_RESULT":
                        store.dispatch(`${ModuleDevice}/updateADBStatus`,  data.payload );
                    break;
                }
              });
        }
    }
}