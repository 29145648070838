import actions from './actions-device.js'
import mutations from './mutations-device.js'
import getters from './getters-device.js'
import state from './state-device.js'

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}