<template>
    <div class="flex items-center">
        <div :class="resultInfo().icon" class="mr-2.5"></div>
        <div class="result-text">{{ resultInfo().text }}</div>
    </div>
</template>

<script setup>
import _enum from '@/enum/enum'
</script>
<script>
export default {
    data() {
        return {

        }
    },
    props: {
        data: {
            default: {},
            typeof: Object
        }
    },
    methods: {
        resultInfo() {
            let result = {};
            switch (this.data.result) {
                case _enum.ResultDevice.NotRunYet:
                    result = {
                        text: 'Chưa chạy...',
                        icon: 'icon-16 icon-remove-fill'
                    }
                    break
                case _enum.ResultDevice.Running:
                    result = {
                        text: 'Đang chạy...',
                        icon: 'icon-16 icon-caret-right'
                    }
                    break
                case _enum.ResultDevice.Success:
                    result = {
                        text: 'Thành công',
                        icon: 'icon-16 icon-success'
                    }
                    break
            }
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>
.result-text {
    color: #000;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
</style>