<template>
  <layout-header-content>
    <template #header>
      <div class="flex group-content-device-header justify-between items-center h-full pl-4 pr-4 header">
        <div>
          <div
            class="flex items-center"
            v-if="!selected || selected.length <= 0">
            <div class="w-48 mr-2">
              <div>
                <h-combobox
                  :data="lstStatus"
                  :display="'display'"
                  :keyValue="'value'"
                  @change="onChangeDeviceStatus"
                  :value="params.status"></h-combobox>
              </div>
            </div>
            <div class="w-60">
              <a-input
                v-model:value="params.search"
                placeholder="Nhập tên/Id thiết bị để tìm kiếm..."
                @keydown="filterData" />
            </div>
          </div>
          <div
            class="flex items-center"
            v-else>
            <div>Đã chọn {{ selected.length }} thiết bị</div>
            <div class="flex items-center ml-6">
              <h-button
                class="mr-2"
                :title="'Tạm dừng'"
                @click="onClickStop"></h-button>
              <h-button
                class="mr-2"
                :title="'Tiếp tục'"
                @click="onClickContinue"></h-button>
              <h-button
                class="mr-2"
                :title="'Gửi CMD'"
                @click="onClickSendCMD"></h-button>
              <h-button
                class="mr-2"
                :title="'Đào coin'"
                @click="onClickMiningCoin"></h-button>
              <div
                class="icon-delete-style mr-2"
                @click="onClickDelete">
                <div class="icon-24 icon-delete-layout"></div>
              </div>
              <div class="option-select-style">
                <h-dropdown
                  :data="options"
                  :display="'text'"
                  @click="optionClick">
                  <template v-slot:icon>
                    <div class="device-group-option flex items-center justify-center">
                      <div class="icon-16 icon-option"></div>
                    </div>
                  </template>
                </h-dropdown>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="icon-24 icon-refresh cusor-pointer" @click="onClickRefresh"></div> -->
        <div>
          <h-button
            :title="'Làm mới'"
            @click="onClickRefresh"></h-button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid-content">
        <h-grid
          ref="gridDevice"
          :columns="columns"
          :data="GetDevices"
          :checkbox="true"
          :keyField="'deviceId'"
          :pagination="true"
          :dataPagination="GetTotalInfo"
          @changePagination="changePagination"
          @onSelectedAll="onSelectedAll"
          @onSelected="onSelected"
          :isRowInActive="true"
          :selected="selected">
          <template #widget="slotProps">
            <DeviceWidget
              @widgetEvent="widgetEvent"
              :data="slotProps.data" />
          </template>
          <template #template="slotProps">
            <StatusDevice
              @widgetEvent="widgetEvent"
              :data="slotProps.data"
              :status="slotProps.status" />
          </template>
        </h-grid>
      </div>
    </template>
    <template v-slot:footer></template>
  </layout-header-content>
  <SelectGroup
    @okDialog="okDialogSelectGroup"
    @cancelDialog="cancelDialogSelectGroup"
    :isShow="isShowSelectGroup"
    :title="
      isRemoveGroupMany
        ? `Xóa khỏi nhóm`
        : `Chọn nhóm cho ${isSelectGroupMany ? `thiết bị chọn` : rowDevice.deviceName}`
    "
    :currentItem="rowDevice"
    :rows="selected" />
  <SelectDeviceMiningCoin
    @okDialog="okDialogSelectMiningCoin"
    @cancelDialog="cancelDialogSelectMiningCoin"
    :isShow="isShowSelectMiningCoin"
    :title="`Chọn coin đào cho ${selected.length} thiết bị`"
    :devices="selected"
    :isMiningSetup="isMiningSetup" />
  <SendCMDDevice
    @okDialog="okDialogSendCMD"
    @cancelDialog="cancelDialogSendCMD"
    :isShow="isShowSendCMD"
    :title="`Gửi lệnh CMD cho ${selected.length} thiết bị`"
    :devices="selected"
    :isMiningSetup="isMiningSetup" />
  <DeviceDetail
    @okDialog="okDialogDeviceDetail"
    @cancelDialog="cancelDialogDeviceDetail"
    :isShow="isShowDeviceDetail"
    :title="`Sửa thiết bị`"
    :device="rowDevice"
    :mode="modeDetail" />
</template>

<script setup>
import { ModuleDevice, ModuleGroupDevice, ModuleProxy } from '@/store/module-const'
import DeviceWidget from './DeviceWidget.vue'
import _enum from '@/enum/enum'
import SelectGroup from './SelectGroup.vue'
import SelectDeviceMiningCoin from './SelectDeviceMiningCoin.vue'
import SendCMDDevice from './SendCMDDevice'
import DeviceDetail from './detail/DeviceDetail'
import StatusDevice from './detail/StatusDevice'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import _ from 'lodash'
import messageNotification from '@/utils/messageNotification/messageNotification'
import wsClient from '@/wsClient'
import electronAPI from '@/electronAPI'
</script>

<script>
export default {
  data() {
    return {
      columns: [],
      selected: [],
      rowDevice: {},
      search: '',
      isShowSelectGroup: false,
      isShowSelectMiningCoin: false,
      isMiningSetup: false,
      isShowSendCMD: false,
      isShowDeviceDetail: false,
      params: {
        pageIndex: 1,
        pageSize: 50,
        totalRecord: 0,
        search: '',
        status: -1
      },
      modeDetail: _enum.Mode.Edit,
      lstStatus: [],
      options: [],
      isSelectGroupMany: false,
      isRemoveGroupMany: false
    }
  },
  computed: {
    ...mapGetters(ModuleDevice, ['TotalInfo', 'Devices', 'DevicePage']),
    ...mapGetters(ModuleGroupDevice, ['GroupDevice', 'IsAll', 'GroupDevices']),
    GetDevices() {
      return this.Devices
      // return this.GroupDevice.listDevices ? this.GroupDevice.listDevices : [];
    },

    GetTotalInfo() {
      if (this.IsAll) return this.TotalInfo
      let devices = this.GroupDevice.listDevices ? this.GroupDevice.listDevices : []
      if (!devices || devices.length <= 0)
        return {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          totalRecord: 0
        }
      return {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        totalRecord: devices.length
      }
    }
  },
  watch: {
    'param.search'(newVal, oldVal) {
      const me = this
      if (newVal && newVal.trim()) {
        me.params.search = newVal.trim()
      }
    },
    async IsAll(newVal, oldVal) {
      const me = this
      if (newVal) {
        let payload = { ...me.params }
        await me.getDevicePaging(payload)
      }
    },
    async GroupDevice(newVal, oldVal) {
      const me = this
      let payload = {
        ...me.params,
        deviceIds: this.GroupDevice.listDevices,
        groupId: this.GroupDevice.groupId
      }
      await me.getDeviceGroupPaging(payload)
    }
  },
  async created() {
    const me = this
    me.initDataStatic()
    await me.getData()
  },
  methods: {
    ...mapActions(ModuleDevice, [
      'getDevices',
      'getDevicePaging',
      'updateDevice',
      'deleteDevice',
      'deleteDevices',
      'getDeviceGroupPaging',
      'updateDeviceInClient',
      'updateViewDevices'
    ]),

    ...mapActions(ModuleGroupDevice, ['updateGroupDevice']),
    ...mapActions(ModuleProxy, ['autoAddProxy']),
    ...mapActions(['changeLoadingStatus']),
    initDataStatic() {
      this.columns = [
        {
          title: 'Tên',
          dataIndex: 'deviceName',
          sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
          sortDirections: ['descend', 'ascend'],
          height: 30,
          width: 130
        },
        {
          title: 'Model',
          dataIndex: 'model',
          sorter: (a, b) => a.model.localeCompare(b.model),
          sortDirections: ['descend', 'ascend'],
          width: 85
        },
        {
          title: 'Version',
          dataIndex: 'workerVersion',
          sorter: (a, b) => a.model.localeCompare(b.workerVersion),
          sortDirections: ['descend', 'ascend'],
          width: 65
        },

        {
          title: 'Ip local',
          dataIndex: 'sshUser',
          sorter: (a, b) => a.sshUser.localeCompare(b.sshUser),
          sortDirections: ['descend', 'ascend'],
          width: 100
        },
        {
          title: 'Wifi',
          dataIndex: 'wifiInfo',
          sorter: (a, b) => a.sshUser.localeCompare(b.wifiInfo),
          sortDirections: ['descend', 'ascend'],
          width: 175
        },
        {
          title: 'Mhz',
          dataIndex: 'cpumhz',
          sorter: (a, b) => a.cpumhz.localeCompare(b.cpumhz),
          sortDirections: ['descend', 'ascend'],
          width: 65
        },
        
        {
          title: 'Cpu',
          dataIndex: 'cpuUse',
          sorter: (a, b) => a.cpuUse - b.cpuUse,
          sortDirections: ['descend', 'ascend'],
          isPercent: true,
          width: 50
        },
        {
          title: '°C',
          dataIndex: 'tempe',
          sorter: (a, b) => a.tempe - b.tempe,
          sortDirections: ['descend', 'ascend'],
          width: 50
        },
        {
          title: 'Root',
          dataIndex: 'root',
          sorter: (a, b) => a.root - b.root,
          sortDirections: ['descend', 'ascend'],
          status: true,
          template: true,
          width: 50
        },
        {
          title: 'Chạy',
          dataIndex: 'isRunning',
          sorter: (a, b) => a.isMining - b.isMining,
          sortDirections: ['descend', 'ascend'],
          status: true,
          template: true,
          width: 50
        },
        // {
        //   title: "Coin",
        //   dataIndex: "miningCoin",
        //   sorter: (a, b) => a.miningCoin - b.miningCoin,
        //   sortDirections: ["descend", "ascend"],
        // },
        {
          title: 'Công việc',
          dataIndex: 'statusMessage',
          sorter: (a, b) => a.statusMessage - b.statusMessage,
          sortDirections: ['descend', 'ascend']
        },
        {
          title: 'Time',
          dataIndex: 'lastInOut',
          sorter: (a, b) => a.lastInOut.localeCompare(b.lastInOut),
          sortDirections: ['descend', 'ascend'],
          formatType: _enum.FormatType.Time,
          width: 90
        },
        {
          title: 'Chức năng',
          dataIndex: 'action',
          widgeOption: DeviceWidget,
          width: 100
        }
      ]
      this.lstStatus = [
        {
          display: 'Tất cả',
          value: -1
        },
        {
          display: 'Online',
          value: 1
        },
        {
          display: 'Offline',
          value: 0
        }
      ]

      this.options = [
        {
          text: 'Chuyển vào nhóm',
          action: _enum.Event.MoveGroup
        },
        {
          text: 'Xóa khỏi nhóm',
          action: _enum.Event.Delele
        },
        // {
        //   text: 'Phân bổ proxy',
        //   action: _enum.Event.AddProxy
        // },
        {
          text: 'Cập nhật tool trên thiết bị',
          action: _enum.Event.RebootDevice
        }

        // {
        //   text: 'Thêm vào danh sách xem',
        //   action: _enum.Event.AddToViewDevices
        // }
      ]
    },
    async getData() {
      const me = this
      let payload = { ...me.params }
      if (this.IsAll) {
        await me.getDevicePaging(payload)
      } else {
        payload = { ...payload, deviceIds: this.GroupDevice.listDevices }
        await me.getDeviceGroupPaging(payload)
      }
    },
    filterData: _.debounce(async function () {
      const me = this
      me.resetPage()
      await me.getData()
    }, 200),
    async changePagination(params) {
      const me = this
      me.params = { ...me.params, ...params }
      me.getData()
    },

    onSelected(params) {
      this.selected = params.selectedRows
    },

    onSelectedAll(params) {
      this.selected = params.selectedRows
    },

    async widgetEvent(payload) {
      var me = this
      if (!payload || !payload.data || !payload.event) return
      me.rowDevice = _.cloneDeep(payload.data)
      switch (payload.event) {
        case _enum.Event.Add:
          break
        case _enum.Event.Edit:
          me.modeDetail = _enum.Mode.Edit
          me.isShowDeviceDetail = true
          break
        case _enum.Event.View:
          // this.modeDetail = _enum.Mode.View
          // this.isShowDeviceDetail = true
          electronAPI.viewPhone(this.rowDevice)
          me.changeLoadingStatus('loading')
          setTimeout(() => {
            me.changeLoadingStatus(false)
          }, 2000)
          break
        case _enum.Event.Delele:
          await me.onClickDeleteRow(me.rowDevice.deviceId)
          break
        case _enum.Event.Restart:
          wsClient.rebootDevice([this.rowDevice])
          break
        case _enum.Event.MoveGroup:
          me.isShowSelectGroup = true
          break
      }
    },

    async okDialogSelectGroup(payload) {
      this.isShowSelectGroup = false
      let groupDevice = this.GroupDevices.find((x) => x.groupId == payload.groupId)
      if (!groupDevice || !groupDevice.listDevices) groupDevice.listDevices = []

      if (this.isRemoveGroupMany) {
        let deviceIds = this.selected.map((x) => x.deviceId)
        groupDevice.listDevices = groupDevice.listDevices.filter((x) => !deviceIds.includes(x))
        this.resetSelectRows()
      } else {
        if (this.isSelectGroupMany) {
          let deviceIdsSelect = this.selected.map((x) => x.deviceId)
          let deviceAdd = deviceIdsSelect.filter((x) => !groupDevice.listDevices.includes(x))
          if (deviceAdd && deviceAdd.length > 0) {
            groupDevice.listDevices.push(...deviceAdd)
          }
          this.resetSelectRows()
        } else {
          let checkExist = groupDevice.listDevices.find((x) => x.deviceId == this.rowDevice.deviceId)
          if (!checkExist) {
            groupDevice.listDevices.push(this.rowDevice.deviceId)
          }
        }
      }
      let res = await this.updateGroupDevice(groupDevice)
      if (res) {
        messageNotification.messageNotification('Cập nhật thành công')
        await this.getData()
      }
      this.isRemoveGroupMany = false
      this.isSelectGroupMany = false
    },

    cancelDialogSelectGroup() {
      this.isShowSelectGroup = false
    },

    okDialogSelectMiningCoin(payload) {
      this.isShowSelectMiningCoin = false
      if (payload) {
        wsClient.ChangeJob(this.selected, payload)
        //update status of device store
        this.selected.forEach((element) => {
          element.isRunning = false
          element.JobAssign = payload.job
          this.updateDeviceInClient(element)
        })
      }
    },

    cancelDialogSelectMiningCoin() {
      this.isShowSelectMiningCoin = false
      this.isMiningSetup = false
    },

    okDialogSendCMD() {
      this.isShowSendCMD = false
      messageNotification.messageNotification('Cập nhật thành công')
    },

    cancelDialogSendCMD() {
      this.isShowSendCMD = false
    },

    async okDialogDeviceDetail(payload) {
      this.isShowDeviceDetail = false
      if (!payload) return
      await this.updateDevice(payload)
      await this.getData()
    },

    cancelDialogDeviceDetail() {
      this.isShowDeviceDetail = false
    },

    onClickStop() {
      //update status of device store
      this.selected.forEach((element) => {
        element.isRunning = false
        this.updateDeviceInClient(element)
      })
      wsClient.ChangeStatusJob(this.selected, false)
    },

    onClickContinue() {
      //update status of device store
      this.selected.forEach((element) => {
        element.isRunning = false
        this.updateDeviceInClient(element)
      })
      wsClient.ChangeStatusJob(this.selected, true)
    },

    onClickSendCMD() {
      this.isShowSendCMD = true
    },

    onClickMiningCoin() {
      this.isMiningSetup = true
      this.isShowSelectMiningCoin = true
    },

    onClickStartJob() {
      this.isMiningSetup = false
      this.isShowSelectMiningCoin = true
    },

    onClickDelete: _.debounce(async function onClickDelete() {
      let payload = {
        deviceIds: this.selected.map((x) => x.deviceId)
      }
      let res = await this.deleteDevices(payload)
      if (res) {
        await this.getData()
        messageNotification.messageNotification('Xóa thành công')
        this.resetSelectRows()
      } else {
        messageNotification.messageNotification('Xóa thất bại')
      }
    }, 300),

    onClickDeleteRow: _.debounce(async function (deviceId) {
      let payload = {
        deviceIds: [deviceId]
      }
      let res = await this.deleteDevices(payload)
      if (res) {
        await this.getData()
        messageNotification.messageNotification('Xóa thành công')
        this.resetSelectRows()
      } else {
        messageNotification.messageNotification('Xóa thất bại')
      }
    }, 300),

    async onClickRefresh() {
      await this.getData()
    },

    async onChangeDeviceStatus(value) {
      this.params.status = value
      this.resetPage()
      await this.getData()
    },
    async optionClick(item) {
      switch (item.action) {
        case _enum.Event.MoveGroup:
          this.isSelectGroupMany = true
          this.isShowSelectGroup = true
          break
        case _enum.Event.Delele:
          if (!this.IsAll && this.GroupDevice) {
            let groupDevice = _.cloneDeep(this.GroupDevice)
            let deviceIds = this.selected.map((x) => x.deviceId)
            groupDevice.listDevices = groupDevice.listDevices.filter((x) => !deviceIds.includes(x))

            this.resetSelectRows()
            let res = await this.updateGroupDevice(groupDevice)
            if (res) {
              messageNotification.messageNotification('Cập nhật thành công')
              await this.getData()
            }
          }
          break
        case _enum.Event.AddProxy:
          if (this.selected && this.selected.length > 0) {
            this.autoAddProxy(this.selected.map((device) => device.deviceId))
          } else {
            messageNotification.messageNotification('Vui lòng chọn thiết bị.', 'error')
          }

          break
        case _enum.Event.RebootDevice:
          if (this.selected && this.selected.length > 0) {
            wsClient.rebootDevice(this.selected)
          } else {
            messageNotification.messageNotification('Vui lòng chọn thiết bị.', 'error')
          }
          break
        case _enum.Event.AddToViewDevices:
          if (this.selected && this.selected.length > 0) {
          } else {
            messageNotification.messageNotification('Vui lòng chọn thiết bị.', 'error')
          }

          break
      }
    },
    resetPage() {
      this.params.pageIndex = 1
    },
    resetSelectRows() {
      const me = this
      // if (!me.$refs.gridDevice) return;
      me.selected = []
      // me.$refs.gridDevice.resetSelectRow();
    }
  }
}
</script>

<style lang="scss" scoped>
// @import url("@/assets/contents/scss/views/device/groupContentDevice.scss");
.grid-content {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.icon-delete-style {
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color, #dcdfe6);
  background: var(--fill-color-blank, #fff);
  cursor: pointer;
}

.option-select-style {
  display: flex;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color, #dcdfe6);
  background: var(--fill-color-blank, #fff);
  cursor: pointer;
}
</style>
