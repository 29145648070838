import { moduleStore } from '@/store/store'
export default {
    updateGroupDevices(state, payload) {
        //Đếm lại trạng thại các thiết bị
        for (var item in payload) {
            payload[item].totalDevices = 0; // Sử dụng payload[item] để truy cập giá trị của thuộc tính
            if (payload[item].listDevices) {
                payload[item].totalDevices = payload[item].listDevices.length;
            }
        }

        state.GroupDevices = [...payload];
    },

    addGroupDevice(state, payload) {
        if (!state.GroupDevices) { state.GroupDevices = [] }
        state.GroupDevices.push(payload);
    },

    updateGroupDevice(state, payload) {
        if (!state.GroupDevices) { state.GroupDevices = [] }
        let index = state.GroupDevices.findIndex(x => x.groupId == payload.groupId);
        if (index >= 0) {
            state.GroupDevices[index].groupName = payload.groupName;
            state.GroupDevices[index].listDevices = payload.listDevices;
            // moduleStore.modules['device'].state.Devices = payload.listDevices;
            if (payload.listDevices) {
                state.GroupDevices[index].totalDevices = payload.listDevices.length;
            }
            state.GroupDevice = state.GroupDevices[index];
        }
    },

    deleteGroupDevice(state, payload) {
        state.GroupDevices = state.GroupDevices.filter(x => x.groupId != payload.groupId);
    },
    updateGroupDeviceActive(state, payload) {
        state.GroupDevice = { ...payload };
    },

    updateIsGroupAll(state, payload) {
        state.IsAll = payload;
    }
}