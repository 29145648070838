<template>
  <div class="h-full">
    <layout-header-content>
      <template #header>
        <div class="flex group-content-device-header justify-between items-center h-full pl-4 pr-4 header">
          <div>
            <h-input :placeholder="'Nhập tên/Id thiết bị để tìm kiếm...'" :width="240"></h-input>
          </div>
          <div class="icon-24 icon-refresh"></div>
        </div>
      </template>
      <template #content>
        <div class="h-full">
          <div class="view-container">
            <div v-for="(item, index) in ViewDevices" :key="index" class="device-view-box">
                <div class="title">{{ item.deviceName||item.deviceId }} | {{ item.model }} | {{ item.localIp }}</div>
                <div class="content">
                    <!-- Kiểm tra nếu adbConnect là true thì nhúng iframe, ngược lại hiển thị thông báo -->
                    <template v-if="item.adbConnect">
                      <iframe :src="getDeviceStreamUrl(item.localIp)" frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <p style="text-align: center; line-height: 100%;">Không thể kết nối ADB</p>
                    </template>
                </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </layout-header-content>
  </div>
</template>
<script setup>
import { mapGetters } from 'vuex';
import { ModuleDevice } from '@/store/module-const'
</script>

<script>
export default {
  data() {
    return {
      workActive: null,
      currentItem: {},
      columns: []
    }
  },
  computed: {
    ...mapGetters(ModuleDevice, [
      'ViewDevices',
    ])
  },
  created() {
  },
  methods: {
    getDeviceStreamUrl(localIp) {
      return `http://localhost:8000/#!action=stream&udid=${localIp}:5555&player=broadway&ws=ws://${localIp}:8886`;
    },
  }
}

</script>

<style lang="scss" scoped>
 .view-container {
            display: flex;
            flex-wrap: wrap;
            margin: auto;
        }
  .device-view-box {
            width: 315px;
            height: 515px;
            margin: 10px;
            box-sizing: border-box;
            border: 1px solid #ccc;
        }

        .title {
            height: 30px;
            background-color: #f0f0f0;
            text-align: center;
            line-height: 30px;
        }

        .content {
            height: calc(100% - 30px);
        }

        iframe {
            width: 100%;
            height: 100%;
        }
          
</style>
