<template>
    <div class="flex items-center">
        <div class="mr-2.5 icon-16 icon-edit cursor-pointer" @click="widgetEvent(_enum.Event.Edit)"></div>
        <div class="flex items-center">
            <h-dropdown :data="options" :display="'text'" @click="optionClick">
                <template v-slot:icon>
                    <div class="script-option flex items-center justify-center">
                        <div class="icon-16 icon-option"></div>
                    </div>
                </template>
            </h-dropdown>
        </div>
    </div>
</template>

<script setup>
import _enum from '@/enum/enum'
import { mapGetters } from 'vuex';
</script>

<script>
export default {
    props: {
        data: {
            default: {},
            typeof: Object
        }
    },
    data() {
        return {
            options: [
                {
                    text: 'Xóa thiết lập',
                    action: _enum.Event.Delele
                }
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    async created() {

    },
    methods: {
        widgetEvent(event) {
            const payload = {
                data: this.data,
                event: event
            }
            this.$emit('widgetEvent', payload)
        },
        optionClick(item) {
            const payload = {
                data: this.data,
                event: item.action
            }
            this.$emit('widgetEvent', payload)
        }
    }

}
</script>


<style lang="scss" scoped>
.script-group-option {
    cursor: pointer;
}
</style>