<template>
  <div>
    <a-modal v-model:open="props.isShow" :title="props.title" :centered="props.centered" @ok="okDialog"
      :cancelText="props.cancelText" :okText="props.okText" :width="props.width" @cancel="cancelDialog"
      @afterClose="afterClose" :zIndex="props.zIndex" :footer="null">
      <div v-if="props.content">{{ props.content }}</div>
      <div v-else class="mt-5 mb-5">
        <slot name="content"></slot>
      </div>
      <div v-if="Object.keys(props.footerDefault).length > 0">
        <div class="footer flex items-center" :class="props.footerPosition == 'right' ? 'justify-end' : ''">
          <div><h-button class="mr-3" :classButton="'btn-cancel'"
              :title="props.footerDefault.cancelText ? props.footerDefault.cancelText : 'Hủy bỏ'"
              :style="props.footerDefault.cancelStyle" @click="cancelDialog"></h-button></div>
          <div><h-button :title="props.footerDefault.okText ? props.footerDefault.okText : 'Đồng ý'"
              :style="props.footerDefault.okStyle" @click="okDialog" :classButton="'btn-ok'"></h-button></div>
        </div>
      </div>
      <div v-else>
        <slot name="footer"></slot>
      </div>
    </a-modal>
  </div>
</template>
<script setup>
const emit = defineEmits(['okDialog', 'cancelDialog', 'afterClose'])
const props = defineProps({
  isShow: {
    default: false,
    typeof: Boolean
  },
  title: {
    default: '',
    typeof: String
  },
  cancelText: {
    default: 'Huỷ',
    typeof: String
  },
  centered: {
    default: true,
    typeof: Boolean
  },
  okText: {
    default: 'Đồng ý',
    typeof: String
  },
  width: {
    default: 520,
    typeof: Number
  },
  zIndex: {
    default: 1000,
    typeof: Number
  },
  content: {
    default: '',
    typeof: String
  },
  headerDefault: {
    default: {},
    typeof: Object
  },
  footerDefault: {
    default: {},
    typeof: Object
  },
  footerPosition: {
    default: 'right',
    typeof: String
  }
})

const okDialog = () => {
  emit('okDialog')
}

const cancelDialog = () => {
  emit('cancelDialog')
}

const afterClose = () => {
  emit('afterClose')
}
</script>
<style lang="scss" scoped>
@import url('@/assets/contents/scss/components/dialog/dialog.scss');
</style>
