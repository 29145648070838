import commonFunctions from '../common/commonFunc.js';
export default {
    async getDevices({ commit, state }) {
        let result = await axios.get(`${globalConfig.apiURL}/mining/getbyuserid/${state.userId}`);
        commit('SET_DEVICES', result.data.data);

        ///console.log(result.data.data);
    },
    deviceNew({ commit, state }, { data }) {
        const device = state.devices.find(x => x.deviceId == data.deviceId);
        if (device) {
            const message = `<b>${device.deviceName}</b>: Đã kết nối`;
            console.log(message);
            commit('ADD_MESSAGE', { id: shortId.generate(), type: 2, message });
            data.status = 1;
            commit('UPDATE_DEVICE', data);
            commit('SET_TOTAL_ONLINE', data.totalOnline);
        }
    },
    deviceDisconect({ commit, state }, { data }) {
        const device = state.devices.find(x => x.deviceId == data.deviceId);
        if (device) {
            const message = `<b>${device.deviceName}</b>: Ngắt kết nối`;
            console.log(message);
            commit('ADD_MESSAGE', { id: shortId.generate(), type: 3, message });
            data.status = 0;
            commit('UPDATE_DEVICE', data);
            commit('SET_TOTAL_ONLINE', data.totalOnline);
        }
    },
    deviceReport({ commit, state }, { data }) {
        const device = state.devices.find(x => x.deviceId == data.deviceId);
        if (device) {
            //const message = `<b>${device.deviceName||device.deviceId}</b>: CPU [${data.cpuUse}%] [${data.tempe|0}°C]`;
            //console.log(message);
            // commit('ADD_MESSAGE', { id: shortId.generate(), type: 1, message });
            //commit('insertLogs',{type:0, logMessage:`${device.deviceName||device.deviceId}`,createDate:Date.now()});
            commit('UPDATE_DEVICE', data);
            commit('SET_TOTAL_ONLINE', data.totalOnline);
        }
    },
    async updateDevice({ commit, state }, deviceInfo) {
        const device = state.devices.find(x => x.deviceId == deviceInfo.deviceId);
        if (device) {
            const resp = await axios.post(`${globalConfig.apiURL}/mining/update`, deviceInfo);
            if (resp.data.status == "success") {
                commit('UPDATE_DEVICE', deviceInfo);
                return true;
            } else {
                commonFunctions.ErrorMess("Lỗi cập nhật thiết bị.");
                return false;
            }
        } else {
            commonFunctions.ErrorMess("Thiết bị không tồn tại.");
            return false;
        }
    },
    async updateManyDevices({ state }, { data }) {
        if (state.deviceSelected && state.deviceSelected.length > 0) {
            commonFunctions.ShowLoading();
            const lstDeviceId = state.deviceSelected.map((d) => d.deviceId);
            const resp = await axios.post(`${globalConfig.apiURL}/device/update-many`, { devices: lstDeviceId, updateData: data });
            if (resp.data.status == "success") {

                for (let item in state.deviceSelected) {
                    if ('isMining' in data) {
                        state.deviceSelected[item].isMining = data.isMining
                    }
                    if (data.miningCoin) {
                        state.deviceSelected[item].miningCoin = data.miningCoin
                    }
                }
                commonFunctions.SuccessMess(`Cập nhật thành công ${state.deviceSelected.length} thiết bị.`);
                const dataPush = {
                    command: 'SEND_UPDATE',
                    data: {
                        userId: state.userId,
                        devices: lstDeviceId
                    }
                }
                commonFunctions.CloseLoading();
                wsClient.send(dataPush)

                return true;
            } else {
                commonFunctions.CloseLoading();
                commonFunctions.ErrorMess("Lỗi cập nhật thiết bị.");
                return false;
            }



        } else {
            commonFunctions.ErrorMess("Vui lòng lựa chọn thiết bị.");
        }
    },
    async deleteDevice({ commit, state }, deviceInfo) {
        const device = state.devices.find(x => x.deviceId == deviceInfo.deviceId);
        if (device) {
            const postData = {
                deviceId: deviceInfo.deviceId,
                userId: deviceInfo.userId,
            }
            const resp = await axios.post(`${globalConfig.apiURL}/mining/delete`, postData);
            if (resp.data.status == "success") {
                commit('DELETE_DEVICE', device);
                return true;
            } else {
                commonFunctions.ErrorMess("Lỗi cập Xóa thiết bị.");
                return false;
            }
        } else {
            commonFunctions.ErrorMess("Thiết bị không tồn tại.");
            return false;
        }
    },
    taskResultMessage({ commit, state }, { data }) {
        const device = state.devices.find(x => x.deviceId === data.deviceId);
        if (device) {
            const message = `[${data.type}] : ${data.message}`;
            commit('TASK_RESULT', { deviceId: device.deviceId, message: message });
        }
    },
    serverMessage({ commit }, { data }) {
        commonFunctions.SuccessMess(data.message);
        if (data.devices && data.devices.length > 0) {
            data.devices.forEach(e => {
                commit('TASK_RESULT', { deviceId: e, message: `Đang thực hiện lệnh...` });
            });

        }
    },
    updateSelectedDevice({ state }, { data }) {
        state.deviceSelected = data;
    },
    async getUserData({ state }) {
        let result = await axios.get(`${globalConfig.apiURL}/user/getbyid/${state.userId}`);
        state.userData = result.data.data
        if (state.userData.timeOutReport) {
            state.userData.timeOutReport = state.userData.timeOutReport / 1000;
        } else {
            state.userData.timeOutReport = 20;
        }
        //console.log(state.userSetting)
    },
    async updateUserConfig({ state }) {
        //alert( JSON.stringify(state.userData.miningConfig) );

        if (!state.userData.miningConfig || !state.userData.miningCoin || !state.userData.timeOutReport) {
            commonFunctions.ErrorMess("Không được để trống thông tin.")
            return false;
        }

        commonFunctions.ShowLoading();
        const postData = {
            userId: state.userId,
            miningConfig: state.userData.miningConfig,
            miningCoin: state.userData.miningCoin,
            timeOutReport: state.userData.timeOutReport * 1000,
        }
        const resp = await axios.post(`${globalConfig.apiURL}/user/update`, postData);
        if (resp.data.status == "success") {
            commonFunctions.SuccessMess("Cập nhật thiết lập thành công.")
            commonFunctions.CloseLoading();
            return true;
        } else {
            commonFunctions.ErrorMess("Lỗi cập Xóa thiết bị.");
            commonFunctions.CloseLoading();
            return false;
        }


    },
    checkUpdateReport({ state }, { data }) {
        state.isUpdateReport = data;
        console.log(data);
    },
    changeLoadingStatus(context,res){
        context.commit("changeLoadingStatus",res);
    }
}