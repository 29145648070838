<template>
  <div class="custom-container">
    <div>
      <h1 class="text-center">Đăng nhập</h1>
      <a-form :model="user" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off"
        @finish="login" @finishFailed="loginFail">
        <a-form-item label="Tài khoản" name="username"
          :rules="[{ required: true, message: 'Vui lòng nhập tên tài khoản! Tài khoản không được để chống' }]">
          <a-input v-model:value="user.username" />
        </a-form-item>

        <a-form-item label="Mật khẩu" name="password"
          :rules="[{ required: true, message: 'Vui lòng nhập mật khẩu! Mật khẩu không được để chống!' }]">
          <a-input-password v-model:value="user.password" />
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
          <a-button type="primary" html-type="submit">Đăng nhập</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import commonFunc from '@/common/commonFunc'
import { ModuleUser } from '@/store/module-const';
import { mapActions, mapGetters } from 'vuex';
</script>

<script>

export default {
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
    }
  },
  computed: {
    ...mapGetters(ModuleUser, [
      'UserId'
    ])
  },
  methods: {
    ...mapActions(ModuleUser, ['loginUser']),
    async login(value) {
      try {
        let res = await this.loginUser(value);
        if (this.UserId) {
          window.location.href = '/device'
        } else {
          commonFunc.ErrorMess(res.data.message);
        }
      } catch (error) {
        commonFunc.ErrorMess(error.message);
      }
    },
    loginFail(errorInfo){
      console.log('Failed:', errorInfo);
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

button {
  background-color: #1677ff !important;
}
</style>