import actions from './actions-setup-account.js'
import mutations from './mutations-setup-account.js'
import getters from './getters-setup-account.js'
import state from './state-setup-account.js'

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}