import electronAPI from "@/electronAPI";
export default {
    updateDeviceInfo(state, payload) {
        if (payload.isAll) {
            state.TotalInfoAll = {
                pageIndex: payload.pageIndex,
                pageSize: payload.pageSize,
                totalRecord: payload.totalRecord,
                totalOnline: payload.totalOnline,
                totalOffline: payload.totalOffline
            }
        }
        state.DevicePage = { ...payload };
        state.TotalInfo = {
            pageIndex: payload.pageIndex,
            pageSize: payload.pageSize,
            totalRecord: payload.totalRecord,
            totalOnline: payload.totalOnline,
            totalOffline: payload.totalOffline
        }
        if (payload && payload.data) {
            state.Devices = payload.data;
        }
        else {
            state.Devices = [];
        }
    },

    updateDevice(state, payload) {
        if (!state.Devices || state.Devices.length <= 0 || !payload) return;
        let index = state.Devices.findIndex(x => x.deviceId == payload.deviceId);
        if (index < 0) return;
        state.Devices[index] = payload;
        state.DevicePage.data = state.Devices;
    },

    deleteDevice(state, payload) {
        if (!state.Devices || state.Devices.length <= 0 || !payload) return;
        state.Devices = state.Devices.filter(x => x.deviceId != payload.deviceId);
        state.DevicePage.data = state.Devices;
    },

    deleteDevices(state, deviceIds) {
        if (!state.Devices || state.Devices.length <= 0 || !deviceIds || deviceIds.length <= 0) return;
        state.Devices = state.Devices.filter(x => !deviceIds.includes(x.deviceId));
        state.DevicePage.data = state.Devices;
    },
    updateDeviceStatus(state, payload) {
        if (!payload.deviceId) {
            console.log('Thiết bị cần có trường "deviceId" để xác định thiết bị cần cập nhật.');
            return;
        }

        const deviceToUpdate = state.Devices.find(device => device.deviceId === payload.deviceId);

        if (!deviceToUpdate) {
            console.log('Thiết bị không tồn tại.');
            return;
        }

        for (const key in payload) {
            if (key !== 'deviceId' && deviceToUpdate.hasOwnProperty(key)) {
                deviceToUpdate[key] = payload[key];
            }
        }

        // console.log('Thiết bị đã được cập nhật:', deviceToUpdate);
        return true;
    },
    updateViewDevices(state, payload) {

        payload.forEach(item => {
            const existingDeviceIndex = state.ViewDevices.findIndex(device => device.deviceId === item.deviceId);
            const deviceNew = {
                deviceId: item.deviceId,
                deviceName: item.deviceName,
                model: item.model,
                AndroidVersion: item.AndroidVersion,
                localIp: item.localIp,
                adbConnect:false,
            };

            if (existingDeviceIndex !== -1) {
                // Nếu thiết bị đã tồn tại, cập nhật thông tin
                state.ViewDevices[existingDeviceIndex] = deviceNew;
            } else {
                // Nếu thiết bị chưa tồn tại, thêm mới vào danh sách
                state.ViewDevices.push(deviceNew);
                
            }
        });
        electronAPI.viewDevices(state.ViewDevices);
        
        return true;

    },
    updateADBStatus(state, payload){
        if(payload&&payload.deviceId){
            const deviceIndex = state.ViewDevices.findIndex(device => device.deviceId === payload.deviceId);
            if (deviceIndex !== -1) {
                state.ViewDevices[deviceIndex].adbConnect = payload.adbConnect;
            }
        }
        
    }


}