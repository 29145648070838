<template>
    <div id="app">
        <FullPage />
        <h-loading></h-loading>
    </div>
</template>

<script setup>
import _enum from "@/enum/enum";
import FullPage from "@/pages/FullPage.vue";
import { mapGetters, mapMutations } from "vuex";
import { ModuleUser } from "@/store/module-const";
</script>

<script>
export default {
    name: "AppWeb",
    components: {
        FullPage,
    },
    data() {
        return {
            screenType: _enum.ScreenType.Computer
        };
    },
    computed: {
        ...mapGetters(ModuleUser, ["UserId"]),
    },
    async created() {
    },

    methods: {
    },
};
</script>

<style scoped lang="scss">
@import url("@/assets/contents/scss/common/common.scss");

#app {
    width: 100%;
    height: 100vh;
}
</style>
