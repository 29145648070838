import { BASE_URL } from '@/api/url';
import _enum from '@/enum/enum';

import ProxyClient from '@/api/proxy/proxy-client';
import messageNotification from "@/utils/messageNotification/messageNotification";
const proxyClient = new ProxyClient();
export default {
    getProxyPaging: async function (context, payload) {
        var res = await proxyClient.postAsync({
            url: `${proxyClient.url}/paging/${proxyClient.userId}`,
            data: payload
        }, true);
        if (res) {
            context.commit('updateProxyPaging', res);
            return res;
        }
        return res;
    },

    getProxies: async function (context, payload) {
        var res = await proxyClient.getAsync({
            url: `${proxyClient.url}/${proxyClient.userId}`,
            queries: payload
        }, true);
        if (res && res.data) {
            context.commit('updateProxies', res.data);
            return res.data;
        }
        return res;
    },

    getProxyById: async function (context, payload) {
        var res = await proxyClient.getAsync({
            url: `${proxyClient.url}/proxy/${payload.proxyId}`,
        }, true);

        if (res && res.data) {
            context.commit('updateProxy', res.data);
            return res.data;
        }
        return res;
    },

    createProxy: async function (context, payload) {
        var res = await proxyClient.postAsync({
            url: `${proxyClient.url}/add`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('addProxy', res.data);
            return res.data;
        }
        return res;
    },

    createManyProxy: async function (context, payload) {
        var res = await proxyClient.postAsync({
            url: `${proxyClient.url}/add-multiple`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('addProxyMany', res.data);
            return res.data;
        }
        return res;
    },

    updateProxy: async function (context, payload) {
        var res = await proxyClient.postAsync({
            url: `${proxyClient.url}/update/${payload.proxyId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('updateProxy', res.data);
            return res.data;
        }
        return res;
    },

    deleteProxy: async function (context, payload) {
        var res = await proxyClient.postAsync({
            url: `${proxyClient.url}/delete/${payload.proxyId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteProxy', res.data);
            return res.data;
        }
        return res;
    },
    deleteProxies: async function (context, payload) {
        var res = await proxyClient.postAsync({
            url: `${proxyClient.url}/delete-multiple`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteProxies', res.data);
            return res.data;
        }
        return res;
    },
    autoAddProxy: async function (context, payload) {
        var res = await proxyClient.postAsync({
            url: `${proxyClient.url}/update-proxy-to-device/${proxyClient.userId}`,
            data: payload
        }, true);

        if (res && res.data) {
            if(res.status=="success")
            {
                context.commit('updateProxiesList', res.data);
                messageNotification.messageNotification("Phân bổ proxy thành công");
                return res.data;
            }else{
                messageNotification.messageNotification(res.message,"error");
            }
           
        }
    }
}