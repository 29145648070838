import actions from './actions-coin.js'
import mutations from './mutations-coin.js'
import getters from './getters-coin.js'
import state from './state-coin.js'

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}