<template>
  <a-pagination
    v-model:current="pageIndexRef"
    v-model:page-size="pageSizeRef"
    :total="totalRecord"
    :disabled="disabled"
    :page-size-options="pageSizeOptions"
    show-size-changer
    :size="size"
    :responsive="responsive"
    :show-total="() => `${textTotalRecords}`"
    @change="onChange"
    @showSizeChange="onShowSizeChange"
  >
    <template #buildOptionText="props">
      <span>{{ props.value }}</span>
    </template>
  </a-pagination>
</template>

<script setup></script>
<script>
export default {
  props: {
    pageIndex: {
      default: 1,
      typeof: Number,
    },
    pageSize: {
      default: 20,
      typeof: Number,
    },
    totalRecord: {
      default: 0,
      typeof: Number,
    },
    textTotal: {
      default: "",
      typeof: String,
    },
    disabled: {
      default: false,
      typeof: Boolean,
    },
    size: {
      default: "small",
      typeof: String,
    },
    prefixOptionText: {
      default: "bản ghi",
      typeof: String,
    },
    responsive: {
      default: false,
      typeof: Boolean,
    },
  },
  data() {
    return {
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      pageIndexRef: this.pageIndex,
      pageSizeRef: this.pageSize,
    };
  },
  computed: {
    textTotalRecords() {
      return this.textTotal ? this.textTotal : `Tổng cộng: ${this.totalRecord}`;
    },
  },
  watch: {
    pageIndex(newVal, oldVal) {
      this.pageIndexRef = newVal;
    },
    pageSize(newVal, oldVal) {
      this.pageSizeRef = newVal;
    },
  },
  methods: {
    onChange(page, pageSize) {
      let params = {
        pageIndex: page,
        pageSize: pageSize,
        totalRecord: this.totalRecord,
      };
      this.$emit("change", params);
    },
  },
  onShowSizeChange(current, pageSize) {
    this.pageIndexRef = current;
    this.pageSizeRef = pageSize;
  },
};
</script>

<style lang="scss" scoped></style>
