<template>
  <div class="group-device">
    <div class="flex justify-between items-center header-group-device">
      <div>Nhóm thiết bị</div>
      <div class="icon-24 icon-add cursor-pointer" @click="onClickAddGroup"></div>
    </div>
    <div class="manage-group-device">
      <div class="form-add-group" v-if="showFormAdd">
        <div class="flex items-center">
          <a-input class="mr-2 input-group-name" v-model:value="currentItem.groupName" placeholder="Nhập tên nhóm" />
          <div class="mr-2 icon-24 icon-confirm-success cursor-pointer" @click="confirmAddGroup"></div>
          <div class="icon-24 icon-confirm-cancel cursor-pointer" @click="cancelAddGroup"></div>
        </div>
      </div>
      <div class="">
        <div class="group-item flex justify-between items-center" :class="IsAll ? 'group-item-active' : ''">
          <div class="text-device cursor-pointer" @click="onCLickAllDevice">
            Tất cả các thiết bị ({{
              (TotalInfoAll.totalOffline
                ? TotalInfoAll.totalOffline
                : 0) + (TotalInfoAll.totalOnline
                  ? TotalInfoAll.totalOnline
                  : 0)
            }})
          </div>
          <div class="flex items-center">
            <div class="devices-inactive mr-1">
              {{ TotalInfoAll.totalOffline ? TotalInfoAll.totalOffline : 0 }}
            </div>
            <div class="devices-active mr-1">
              {{ TotalInfoAll.totalOnline ? TotalInfoAll.totalOnline : 0 }}
            </div>
            <h-dropdown :data="options" :display="'text'">
              <template v-slot:icon>
                <div class="device-group-option flex items-center justify-center">
                  <div class="icon-16 icon-option"></div>
                </div>
              </template>
            </h-dropdown>
          </div>
        </div>
        <div class="">
          <div class="group-item flex justify-between items-center" v-for="(groupDevice, index) in GroupDevices"
            :key="index" :class="!IsAll && GroupDevice.groupId == groupDevice.groupId ? 'group-item-active' : ''">
            <div class="cursor-pointer text-device" @click="onClickGroup(groupDevice, index)">
              {{ groupDevice.groupName }} ({{
                groupDevice.totalDevices ? groupDevice.totalDevices : 0
              }})
            </div>
            <div class="flex items-center">
              <div class="devices-inactive mr-1" v-if="groupDevice.totalDevices !== groupDevice.totalOnline" >
                {{
                  (groupDevice.totalDevices
                    ? groupDevice.totalDevices
                    : 0) - (groupDevice.totalOnline
                      ? groupDevice.totalOnline
                      : 0)
                }}
              </div>
              <div class="devices-active mr-1"  v-if="groupDevice.totalDevices !== groupDevice.totalOnline">
                {{ groupDevice.totalOnline }}
              </div>
              <h-dropdown :data="options" :display="'text'" @click="optionClick" :value="groupDevice">
                <template v-slot:icon>
                  <div class="device-group-option flex items-center justify-center">
                    <div class="icon-16 icon-option"></div>
                  </div>
                </template>
              </h-dropdown>
            </div>
          </div>
        </div>
      </div>
      <LogSystem />
    </div>
    <div></div>
  </div>
</template>

<script setup>
import data_fake from "@/dataFake/data_fake";
import _enum from "@/enum/enum";
import { ref } from "vue";
import _ from "lodash";
import LogSystem from "../logSystem/LogSystem.vue";
import { ModuleGroupDevice, ModuleDevice } from "@/store/module-const";
import { mapGetters, mapActions, mapMutations } from "vuex";
import messageNotification from "@/utils/messageNotification/messageNotification";
</script>

<script>
export default {
  data() {
    return {
      options: [
        {
          text: "Sửa tên",
          action: _enum.Event.Edit,
        },
        {
          text: "Xóa nhóm",
          action: _enum.Event.Delele,
        },
        {
          text: "Dừng hết công việc",
          action: _enum.Event.Stop,
        },
      ],
      indexActive: 0,
      currentItem: {
        groupName: "",
      },
      showFormAdd: false,
      mode: _enum.Mode.Add,
    };
  },
  computed: {
    ...mapGetters(ModuleGroupDevice, ["GroupDevices", "GroupDevice", "IsAll"]),
    ...mapGetters(ModuleDevice, ["TotalInfoAll"]),
    /**
     * Tổng số thiết bị đang online
     */
    totalDeviceOnline() {
      const me = this;
      if (!me.GroupDevices || me.GroupDevices.length <= 0) return 0;
      return me.GroupDevices.reduce((n, { totalOnline }) => n + totalOnline, 0);
    },
    /**
     * Tổng số thiết bị
     */
    totalDevice() {
      const me = this;
      if (!me.GroupDevices || me.GroupDevices.length <= 0) return 0;
      return me.GroupDevices.reduce((n, { totalDevices }) => n + totalDevices, 0);
    },
  },
  watch: {},
  async created() {
    const me = this;
  },
  methods: {
    ...mapActions(ModuleGroupDevice, [
      "getGroupDevices",
      "addGroupDevice",
      "deleteGroupDevice",
      "updateGroupDevice",
    ]),

    ...mapMutations(ModuleGroupDevice, ["updateGroupDeviceActive", "updateIsGroupAll"]),
    async getData() { },

    onCLickAllDevice() {
      const me = this;
      me.updateIsGroupAll(true);
      me.updateGroupDeviceActive({});
    },

    onClickGroup(groupDevice, index) {
      const me = this;
      me.updateGroupDeviceActive(groupDevice);
      me.updateIsGroupAll(false);
      me.indexActive = index;
    },
    onClickAddGroup() {
      this.showFormAdd = true;
    },
    async confirmAddGroup() {
      if (this.mode == _enum.Event.Edit) {
        let res = await this.updateGroupDevice(this.currentItem);
        this.mode = _enum.Event.Add;
        if (res) {
          messageNotification.messageNotification("Sửa nhóm thành công");
        }
      } else {
        let payload = {
          groupId: this.$commonFunc.uuidv4(),
          groupName: this.currentItem.groupName,
        };
        let res = await this.addGroupDevice(payload);
        if (res) {
          messageNotification.messageNotification("Thêm nhóm thành công");
        }
      }
      this.currentItem = {};
    },
    cancelAddGroup() {
      this.showFormAdd = false;
    },
    async optionClick(item) {
      if (!item) return;
      let groupDevice = item.value ? item.value : {};
      switch (item.action) {
        case _enum.Event.Edit:
          this.mode = _enum.Mode.Edit;
          this.currentItem = { ...groupDevice };
          this.showFormAdd = true;
          break;
        case _enum.Event.Delele:
          let focusCurrent = false;
          if (
            this.GroupDevices[this.indexActive] &&
            this.GroupDevices[this.indexActive].groupId == groupDevice.groupId
          ) {
            focusCurrent = true;
          }
          await this.deleteGroupDevice({ groupId: groupDevice.groupId });
          if (focusCurrent) {
            this.onCLickAllDevice();
          }
          break;
        case _enum.Event.Stop:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("@/assets/contents/scss/views/device/groupHeaderDevice.scss");
</style>
