import { BASE_URL } from '@/api/url';
import _enum from '@/enum/enum';

import DeviceClient from '@/api/device/device-client';
const deviceClient = new DeviceClient();
export default {
    getDevicePaging: async function (context, payload) {
        var res = await deviceClient.getAsync({
            url: `${deviceClient.url}/device-paging/${deviceClient.userId}`,
            queries: payload
        }, true);

        if (res) {
            context.commit('updateDeviceInfo', { ...res , isAll : true});
            return res;
        }
        return res;
    },

    getDeviceGroupPaging: async function (context, payload) {
        var res = await deviceClient.postAsync({
            url: `${deviceClient.url}/device-group-paging/${deviceClient.userId}`,
            data: payload
        }, true);

        if (res) {
            context.commit('updateDeviceInfo', res);
            return res;
        }
        return res;
    },

    getDeviceById: async function (context, payload) {
        var res = await deviceClient.getAsync({
            url: `${deviceClient.url}/getbydeviceid/${payload.deviceId}`,
        }, true);

        if (res && res.data) {
            return res.data;
        }
        return res;
    },

    updateDevice: async function (context, payload) {
        var res = await deviceClient.postAsync({
            url: `${deviceClient.url}/update`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('updateDevice', res.data);
            return res.data;
        }
        return res;
    },
    updateDeviceInClient: function(context,payload) {
        context.commit('updateDevice', payload);
    },
    deleteDevice: async function (context, payload) {
        var res = await deviceClient.postAsync({
            url: `${deviceClient.url}/delete/${payload.groupId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteDevice', res.data);
            return res.data;
        }
        return res;
    },
    deleteDevices: async function (context, payload) {
        var res = await deviceClient.postAsync({
            url: `${deviceClient.url}/delete-many/${deviceClient.userId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteDevices', res.data);
            return res.data;
        }
        return res;
    },
    updateDeviceStatus: function(context, payload){
        return context.commit('updateDeviceStatus', payload);
    },
    updateViewDevices: function(context, payload){
        if (payload && payload.length > 0) {
            return context.commit('updateViewDevices', payload);
        }else{
            return false;
        }
        
    },
    updateADBStatus: function(context, payload){
        return context.commit('updateADBStatus', payload);
        
    },
    
    
}