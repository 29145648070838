<template>
    <div v-if="Loading">
        <div class="model-loading"></div>
        <div class="loading">
            <a-spin size="large" />
        </div>
    </div>
</template>
<script setup>
import { mapGetters } from 'vuex';
</script>

<script>

export default {
    name: "HLoading",
    computed: {
        ...mapGetters([
            "Loading"
        ])
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
.loading {
    position: fixed;
    top: 50%;
    left: 50%;
}

.model-loading{
  position: fixed;
  background: rgba(119, 119, 119, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>