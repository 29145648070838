<template>
    <div>
        <h-combobox :data="options" :display="'userName'" :keyValue="'userId'"></h-combobox>
         <h-textarea :valueInput="value"></h-textarea>
        <h-input :valueInput="value" ></h-input>
    </div>
</template>

<script setup>
import { ref } from 'vue'
const options = ref([
    {
        userId: '1',
        userName: 'nvhien',
    },
    {
        userId: '2',
        userName: 'nvhien1',
    },
    {
        userId: '3',
        userName: 'nvhien2',
    },
]);

const value = ref('');
</script>

<style lang="scss" scoped></style>