import { BASE_URL } from '@/api/url';
import _enum from '@/enum/enum';

import TaskScriptClient from '@/api/taskScript/task-script-client';
const taskScriptClient = new TaskScriptClient();
export default {
    getTaskScripts: async function (context, payload) {
        var res = await taskScriptClient.getAsync({
            url: `${taskScriptClient.url}`,
            queries: payload
        }, true);
        if (res && res.data) {
            context.commit('updateTaskScripts', res.data);
            return res.data;
        }
        return res;
    },

    getTaskScriptByType: async function (context, payload) {
        var res = await taskScriptClient.getAsync({
            url: `${taskScriptClient.url}/by-type/${payload.type}`,
        }, true);

        if (res && res.data) {
            context.commit('updateTaskScript', res.data);
            return res.data;
        }
        return res;
    },

    createTaskScript: async function (context, payload) {
        var res = await taskScriptClient.postAsync({
            url: `${taskScriptClient.url}/add`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('addTaskScript', res.data);
            return res.data;
        }
        return res;
    },

    updateTaskScript: async function (context, payload) {
        var res = await taskScriptClient.postAsync({
            url: `${taskScriptClient.url}/update/${payload.scriptId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('updateTaskScript', res.data);
            return res.data;
        }
        return res;
    },

    deleteTaskScript: async function (context, payload) {
        var res = await taskScriptClient.postAsync({
            url: `${taskScriptClient.url}/delete/${payload.scriptId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteTaskScript', res.data);
            return res.data;
        }
        return res;
    },
}