module.exports = {
    Role: {
        User: 1,
        Admin: 2,
    },
    DeviceStatus: {
        Active: 1,
        InActive: 2
    },
    LogSystemStatus: {
        Connect: 1,
        Disconnect: 2,
        JobSuccess: 3,
        PauseError: 4
    },

    ActionSetting: {
        SetupMining: 1,
        ScriptRun: 2,
        SetupWork: 3,
        Account: 4,
        Proxy: 5,
        CommonOption: 6
    },

    TaskProcessStatus: {
        Running: 1,
        Pause: 2
    },
    Event: {
        None: 0,
        Add: 1,
        Edit: 2,
        Delele: 3,
        View: 4,
        More: 5,
        Restart: 6,
        MoveGroup: 7,
        Stop: 8,
        AddProxy : 9,
        AddToViewDevices: 10,
        RebootDevice: 11,
    },

    Mode: {
        View: 0,
        Add: 1,
        Edit: 2,
    },

    ResultDevice: {
        NotRunYet: 0,
        Running: 1,
        Success: 2
    },

    SocialNetWork: {
        Youtube: 1,
        Tiktok: 2,
        Facebook: 3,
        Common : 999
    },

    ProxyType: {
        Turn: 1,
        Static: 2,
    },

    FormatType: {
        Number: 1,
        Date: 2,
        DateTime: 3,
        Time : 4
    },

    ScreenType: {
        Mobile: 1,
        Tablet: 2,
        Computer : 3
    }
}


