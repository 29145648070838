//Layouts
import TheMenu from '@/layouts/TheMenu.vue';
import TheHeader from '@/layouts/TheHeader.vue';
import TheContent from '@/layouts/TheContent.vue';
import LayoutHeaderContentFooter from '@/layouts/LayoutHeaderContentFooter.vue';''
import LayoutHeaderContent from '@/layouts/LayoutHeaderContent.vue'
import LayoutAsideContent from '@/layouts/LayoutAsideContent.vue'
import LayoutContentFooter from '@/layouts/LayoutContentFooter.vue'

//components
import HGrid from '@/components/grid/HGrid.vue';
import HPagination from '@/components/grid/HPagination.vue';
import HCombobox from '@/components/combobox/HCombobox.vue';
import HInput from '@/components/input/HInput.vue';
import HTextarea from '@/components/input/HTextarea.vue';
import HDropdown from '@/components/dropdown/HDropdown.vue';
import HButton from '@/components/button/HButton.vue';
import HCheckbox from  '@/components/checkbox/HCheckbox.vue'

import HDialog from '@/components/dialog/HDialog.vue';
import HLoading from '@/components/loading/HLoading.vue';
export const installComponents = (app) => {
    app.component('the-menu', TheMenu);
    app.component('the-header', TheHeader);
    app.component('the-content', TheContent);
    app.component('layout-header-content-footer', LayoutHeaderContentFooter);
    // app.component('layout-aside-content', LayoutAsideContent);
    app.component('layout-aside-content', LayoutAsideContent);
    app.component('layout-header-content', LayoutHeaderContent);
    app.component('layout-content-footer', LayoutContentFooter)

    app.component('h-grid', HGrid);
    app.component('h-pagination', HPagination);
    app.component('h-combobox', HCombobox);
    app.component('h-input', HInput);
    app.component('h-textarea', HTextarea);
    app.component('h-dropdown', HDropdown);
    app.component('h-button', HButton);
    app.component('h-dialog', HDialog);
    app.component('h-loading', HLoading);
    app.component('h-checkbox', HCheckbox);
}

