import { BASE_URL } from '@/api/url';
import _enum from '@/enum/enum';
import LogDeviceClient from '@/api/log/log-device-client';

const logClient = new LogDeviceClient();
export default {
    getLogs: async function (context, payload) {
        console.log(payload);
        var res = await logClient.getAsync({
            url: `${logClient.url}/toplogs/${logClient.userId}`,
            queries: payload
        }, true);

        if (res) {
            context.commit('updateLogs', res);
            return res;
        }
        return res;
    },
    insertLogs: function (context, payload) {
        const message = {logType:payload.logType,createDate:Date.now()}
        switch(payload.logType) {
            case _enum.LogSystemStatus.Connect:
                message.logMessage = `${payload.deviceName ? payload.deviceName : payload.model}-${payload.localIp} đã kết nối.`
              break;
            case _enum.LogSystemStatus.Disconnect:
                message.logMessage = `${payload.deviceName ? payload.deviceName : payload.model}-${payload.localIp} đã ngắt kết nối.`
              break;
            default:
              // code block
          }
        context.commit('insertLogs', message);
    }
}