<template>
  <div v-if="title" class="mb-2" :class="classTitle">{{ title }}</div>
  <a-select v-model:value="props.value" show-search :placeholder="placeholder" style="width: 100%" :options="props.data"
    :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur" @change="handleChange" :loading="props.loading"
    :popupClassName="props.popupClassName" :autofocus="props.autofocus" :dropdownMenuStyle="props.dropdownMenuStyle"
    @inputKeyDown="inputKeyDown" @mouseleave="mouseleave" @mouseenter="mouseenter"
    :field-names="{ label: `${props.display}`, value: `${props.keyValue}` }" :disabled="disabled"></a-select>
</template>
<script setup>
const emit = defineEmits([
  "change",
  "blur",
  "focus",
  "keyDown",
  "mouseenter",
  "mouseleave",
]);
const props = defineProps({
  classTitle: {
    default: "",
    typeof: String,
  },
  title: {
    default: "",
    typeof: String,
  },
  data: {
    default: [],
    typeof: Array,
  },
  value: {
    default: undefined,
    typeof: Object | String | Number,
  },
  styleCombobox: {
    default: { width: `200 px` },
    typeof: Object,
  },
  placeholder: {
    default: "",
    typeof: String,
  },
  autofocus: {
    default: false,
    typeof: Boolean,
  },
  popupClassName: {
    default: "",
    typeof: String,
  },
  loading: {
    default: false,
    typeof: Boolean,
  },
  dropdownClassName: {
    default: "",
    typeof: String,
  },
  dropdownMenuStyle: {},
  showSearch: {
    default: false,
    typeof: Boolean,
  },
  showArrow: {
    default: false,
    typeof: Boolean,
  },
  keyValue: {
    default: "key",
    typeof: String,
  },
  display: {
    default: "display",
    typeof: String,
  },
  disabled: {
    default: false,
    typeof: Boolean
  }
});

const handleChange = (value) => {
  emit("change", value);
};

const handleBlur = (value) => {
  console.log(value, "blur");
  emit("blur");
};

const handleFocus = (value) => {
  console.log(value, "focus");
  emit("focus");
};

const filterOption = (input, option) => {
  return option[`${props.display}`].toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const mouseleave = () => {
  emit("mouseleave");
};

const mouseenter = () => {
  emit("mouseenter");
};
const inputKeyDown = () => {
  emit("keyDown");
};
</script>
