import { BASE_URL } from '@/api/url';
import _enum from '@/enum/enum';

import JobClient from '@/api/job/job-client';
const jobClient = new JobClient();
export default {
    getJobs: async function (context, payload) {
        var res = await jobClient.getAsync({
            url: `${jobClient.url}/${jobClient.userId}`,
            queries: payload
        }, true);
        if (res &&  res.data) {
            context.commit('updateJobs', res.data);
            return res.data;
        }
        return res;
    },

    getCoinJobs: async function (context, payload) {
        var res = await jobClient.getAsync({
            url: `${jobClient.url}/${jobClient.userId}`,
            queries: payload
        }, true);
        if (res && res.data) {
            context.commit('updateJobsCoin', res.data);
            return res.data;
        }
        return res;
    },

    getJobById: async function (context, payload) {
        var res = await jobClient.getAsync({
            url: `${jobClient.url}/job/${payload.jobId}`,
        }, true);

        if (res && res.data) {
            context.commit('updateJob', res.data);
            return res.data;
        }
        return res;
    },

    createJob : async function (context, payload) {
        var res = await jobClient.postAsync({
            url: `${jobClient.url}/add`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('addJob', res.data);
            return res.data;
        }
        return res;
    },

    updateJob: async function (context, payload) {
        var res = await jobClient.postAsync({
            url: `${jobClient.url}/update/${payload.jobId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('updateJob', res.data);
            return res.data;
        }
        return res;
    },

    deleteJob: async function (context, payload) {
        var res = await jobClient.postAsync({
            url: `${jobClient.url}/delete/${payload.jobId}`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteJob', res.data);
            return res.data;
        }
        return res;
    },
    deleteJobs: async function (context, payload) {
        var res = await jobClient.postAsync({
            url: `${jobClient.url}/deletedJobs`,
            data: payload
        }, true);

        if (res && res.data) {
            context.commit('deleteJobs', res.data);
            return res.data;
        }
        return res;
    }
}