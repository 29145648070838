<template>
  <div>
    <div class="flex items-center justify-between pt-5 pl-6 pr-5 pb-6">
      <div class="title-setting">Thiết lập đào coin</div>
      <div>
        <h-button :title="'Thêm mới'" :type="'primary'" @click="onClickAdd"></h-button>
      </div>
    </div>
    <div class="mt-3">
      <h-grid :columns="columns" :data="JobsCoin" :keyField="'jobId'">
        <template #widget="slotProps">
          <SetupCoinWidget @widgetEvent="widgetEvent" :data="slotProps.data" />
        </template>
      </h-grid>
    </div>
    <SetupMiningDetail @okDialog="okDialogJobDetail" @cancelDialog="cancelDialogJobDetail" :isShow="isShowDetail"
      :title="`Thêm mới loại coin`" :currentItem="jobCoin" :mode="mode" />
  </div>
</template>

<script setup>
import { ModuleDevice, ModuleJob, ModuleUser } from "@/store/module-const";
import { mapGetters, mapActions } from "vuex";
import _enum from "@/enum/enum";
import commonFunc from "@/common/commonFunc";
import SetupCoinWidget from "./SetupCoinWidget";
import SetupMiningDetail from "./SetupMiningDetail";
import messageNotification from "@/utils/messageNotification/messageNotification";
</script>

<script>
export default {
  props: {},
  computed: {
    ...mapGetters(ModuleJob, ["JobsCoin"]),
    ...mapGetters(ModuleUser, ["UserId"]),
  },
  data() {
    return {
      columns: [],
      searchText: "",
      isShowDetail: false,
      jobCoin: {
        userId: this.UserId,
        jobId: commonFunc.uuidv4(),
        type: 0,
        jobScripts: {
          filePath: "",
          param: "",
        },
      },
      mode: _enum.Mode.Add,
    };
  },
  async created() {
    this.initDataStatic();
    await this.getData();
  },
  methods: {
    ...mapActions(ModuleJob, ["deleteJob", "getCoinJobs"]),
    async getData() {
      let payload = {
        type: 0,
      };
      await this.getCoinJobs(payload);
    },
    initDataStatic() {
      this.columns = [
        {
          title: "Tên",
          dataIndex: "jobName",
          sorter: (a, b) => a.jobName - b.jobName,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Đường dẫn tool",
          dataIndex: "filePath",
          sorter: (a, b) => a.filePath - b.filePath,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Param thiết lập",
          dataIndex: "param",
          sorter: (a, b) => a.param - b.param,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Chức năng",
          dataIndex: "action",
        },
      ];
    },

    onClickAdd() {
      this.jobCoin = {
        userId: this.UserId,
        jobId: commonFunc.uuidv4(),
        type: 0,
        jobScripts: {
          filePath: "",
          param: "",
        },
      };
      this.isShowDetail = true;

    },

    async widgetEvent(payload) {
      if (!payload || !payload.data || !payload.event) return;
      this.jobCoin = _.cloneDeep(payload.data);
      switch (payload.event) {
        case _enum.Event.Edit:
          this.isShowDetail = true;
          this.mode = _enum.Mode.Edit;
          break;
        case _enum.Event.Delele:
          await this.deleteJobAsync({ jobId: this.jobCoin ? this.jobCoin.jobId : "" });
          break;
      }
    },

    deleteJobAsync: _.debounce(async function (job) {
      let res = await this.deleteJob(job);
      if (res) {
        messageNotification.messageNotification("Xóa thành công");
      } else {
        messageNotification.messageNotification("Xóa thất bại");
      }
      await this.getData({ type: 0 });
    }, 300),
    async okDialogJobDetail(payload) {
      await this.getData({ type: 0 });
      if (this.mode == _enum.Mode.Edit) {
        messageNotification.messageNotification("Sửa thành công");
      } else {
        messageNotification.messageNotification("Thêm thành công");
      }
      this.resetData();
    },

    cancelDialogJobDetail() {
      this.resetData();
    },
    resetData() {
      this.isShowDetail = false;
      this.mode = _enum.Mode.Add;
      this.jobCoin = {
        userId: this.UserId,
        jobId: commonFunc.uuidv4(),
        type: 0,
        jobScripts: {
          filePath: "",
          param: "",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title-setting {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
