<template>
  <div class="devices-list">
    <div class="device-item">
      <div class="device-info">
        <div class="row1">
          <div class="col1">
            <div class="device-name">
              <div class="check-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <g clip-path="url(#clip0_1140_4155)">
                    <path
                      d="M11.3335 2.22676C12.339 2.80733 13.1755 3.64044 13.76 4.64362C14.3446 5.64681 14.6571 6.78527 14.6664 7.94632C14.6758 9.10736 14.3818 10.2507 13.8135 11.2632C13.2452 12.2757 12.4223 13.1222 11.4263 13.719C10.4303 14.3157 9.29574 14.642 8.13489 14.6655C6.97405 14.689 5.8272 14.4089 4.80787 13.8529C3.78854 13.297 2.93208 12.4844 2.32327 11.4958C1.71447 10.5071 1.37444 9.37657 1.33683 8.21609L1.3335 8.00009L1.33683 7.78409C1.37416 6.63275 1.70919 5.51073 2.30926 4.52742C2.90932 3.54411 3.75393 2.73306 4.76076 2.17335C5.76758 1.61363 6.90226 1.32436 8.05417 1.33372C9.20607 1.34308 10.3359 1.65076 11.3335 2.22676ZM10.4715 6.19542C10.3567 6.08064 10.204 6.01169 10.0419 6.0015C9.87993 5.99131 9.71976 6.04059 9.5915 6.14009L9.52883 6.19542L7.3335 8.39009L6.4715 7.52876L6.40883 7.47342C6.28055 7.37399 6.12041 7.32477 5.95843 7.33499C5.79645 7.34521 5.64377 7.41417 5.529 7.52893C5.41424 7.6437 5.34528 7.79638 5.33506 7.95836C5.32485 8.12034 5.37407 8.28048 5.4735 8.40876L5.52883 8.47142L6.86216 9.80476L6.92483 9.86009C7.04174 9.9508 7.18552 10 7.3335 10C7.48147 10 7.62525 9.9508 7.74216 9.86009L7.80483 9.80476L10.4715 7.13809L10.5268 7.07542C10.6263 6.94716 10.6756 6.78699 10.6654 6.62497C10.6552 6.46295 10.5863 6.31022 10.4715 6.19542Z"
                      fill="#6D6D6D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1140_4155">
                      <rect
                        width="16"
                        height="16"
                        fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="item-name"><span class="item-index">#1</span> A02-1</div>
            </div>
            <div class="hash-info">VRSC - Hash: <span class="hash-number">4633.95</span></div>
          </div>
          <div class="col-temp">79</div>
          <div class="col-cpu">100%</div>
          <div class="col-mhz">2.136 Mhz</div>
        </div>
        <div class="device-desc">
          <div class="desc-item lastInOut">
            <span class="desc-icon">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <g clip-path="url(#clip0_1140_4171)">
                  <path
                    d="M8 4.66675V8.00008L9.33333 9.33341"
                    stroke="#E86164"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M11.3335 14.6667L14.6668 12.6667L11.3335 10.6667V14.6667Z"
                    stroke="#E86164"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M8.67808 13.9621C7.42333 14.1047 6.15556 13.8478 5.05534 13.2279C3.95511 12.6081 3.07851 11.6568 2.55039 10.5097C2.02226 9.36265 1.86953 8.07817 2.11395 6.83921C2.35836 5.60026 2.98747 4.47001 3.91166 3.60943C4.83586 2.74885 6.00802 2.2018 7.26123 2.04621C8.51444 1.89062 9.78479 2.1344 10.8914 2.74286C11.998 3.35131 12.8844 4.2934 13.4243 5.43496C13.9643 6.57653 14.1303 7.85936 13.8987 9.10078"
                    stroke="#E86164"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_1140_4171">
                    <rect
                      width="16"
                      height="16"
                      fill="white" />
                  </clipPath>
                </defs>
            </svg>
            </span>
            5p 8s
          </div>
          <div class="desc-item">7.0-2.6</div>
          <div class="desc-item">10.10.255.153</div>
          <div class="desc-item">N920C</div>
        </div>
      </div>
      <div class="device-action">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none">
          <g clip-path="url(#clip0_1140_4166)">
            <path
              d="M10 12.5C10 13.0304 10.2107 13.5391 10.5858 13.9142C10.9609 14.2893 11.4696 14.5 12 14.5C12.5304 14.5 13.0391 14.2893 13.4142 13.9142C13.7893 13.5391 14 13.0304 14 12.5C14 11.9696 13.7893 11.4609 13.4142 11.0858C13.0391 10.7107 12.5304 10.5 12 10.5C11.4696 10.5 10.9609 10.7107 10.5858 11.0858C10.2107 11.4609 10 11.9696 10 12.5Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M21 12.5C18.6 16.5 15.6 18.5 12 18.5C8.4 18.5 5.4 16.5 3 12.5C5.4 8.5 8.4 6.5 12 6.5C15.6 6.5 18.6 8.5 21 12.5Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_1140_4166">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ModuleDevice, ModuleGroupDevice, ModuleProxy } from '@/store/module-const'
</script>
<script>
export default {
  
}
</script>

<style scoped>
.devices-list {
}
.device-item {
  width: 100%;
  display: inline-flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  border-radius: 2px;
  border: 1px solid var(--21, #515151);
  background: #383838;
  font-size:13px;
}
.device-info {
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex:1;
color: #9FA8AD;
}
.row1{
    display: flex;
align-items: center;
gap: 8px;
align-self: stretch;
}
.device-info .col1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
}
.device-info .col1 .device-name {
  display: flex;
  align-items: center;
  gap: 4px;
}
.check-icon {
  width: 16px;
  height: 16px;
}
.item-name {
  color: var(--white, #fff);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.item-index {
  color: var(--white, #67c23a);
  font-weight: 400;
}
.col-temp{
    width: 39px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #FE9923;
    color: #fff;
    text-align: center;
    line-height: 28px;
}
.hash-info {
  color: #9fa8ad;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.hash-number {
  color: #fff;

  font-weight: 500;
}

.device-info .device-desc {
  display: flex;
  align-items: flex-start;
  gap: 2px;
}
.desc-item{
    border-right: 1px solid #D9D9D9;
    padding:0 6px;
}
.desc-item:last-child{
    border:none !important;
}
.desc-item:first-child{
    padding-left:0px;
}
.lastInOut{
    display: flex;
    gap:4px;
    align-items: center;
}
.device-action {
  display: flex;
  align-items: flex-start;
  gap: 9px;
}
</style>
