
import _enum from "@/enum/enum"
export default {
    Coins: [
        {
            id: 1,
            name: 'Bit coin'
        },
        {
            id: 2,
            name: 'BNB'
        },
        {
            id: 3,
            name: 'VRSC'
        }
    ]
}