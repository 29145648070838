<template>
  <div class="w-full">
    <a-dropdown :placement="props.placement" :arrow="props.arrow" :disabled="props.disabled"
      :overlayClassName="props.overlayClassName" :trigger="props.trigger">
      <div v-if="props.type == 'button'">
        <a-button @click="showDropdown">{{ props.text }}</a-button>
      </div>
      <div v-else>
        <slot name="icon"></slot>
      </div>
      <template #overlay>
        <a-menu class="pl-5 pr-5 pt-1 pb-1">
          <slot name="itemView"></slot>
          <a-menu-item v-for="(item, index) in props.data" :key="index" @click="itemClick(item)">
            <div>{{ props.display ? item[`${props.display}`] : item }}</div>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup>
const props = defineProps({
  placement: "bottomLeft", // các vị trí của dropdown : 'bottomLeft', 'bottom', 'bottomRight', 'topLeft', 'top', 'topRight']
  data: {
    default: [],
    typeof: Array,
  },
  key: {
    default: "",
    typeof: String,
  },
  display: {
    default: "display",
    typeof: String,
  }, //field dữ liệu hiển thị (nếu item là object), item là dữ liệu hiển thị không cần hiển thị
  type: {
    default: "",
    typeof: String,
  }, //button/icon,
  text: {
    default: "",
    typeof: String,
  },
  arrow: {
    default: false,
    typeof: Boolean,
  }, //Có mũi tên ở trong dropdown hay không trỏ hay không
  disabled: {
    default: false,
    typeof: Boolean,
  }, //Disabled dropdown,
  overlayClassName: "",
  trigger: {
    default: ["click"],
    typeof: Array,
  }, //Các action để show dropdown : ['click','hover','contextmenu']
  value: {
    default: {},
    typeof: Object
  }
});
const emit = defineEmits(["click"]);
const itemClick = (item) => {
  emit("click", { ...item, value: props.value });
};
</script>

<style lang="scss" scoped></style>
