
import _enum from "@/enum/enum"
export default {
    Devices: [],
    TotalInfo: {
        totalRecord: 0,
        pageSize: 50,
        pageIndex: 1,
        totalOnline : 0
    },
    TotalInfoAll: {
        totalRecord: 0,
        pageSize: 50,
        pageIndex: 1,
        totalOnline: 0
    },
    TotalInfoGroup: {
        totalOnline: 0,
        totalRecord : 0
    },
    DevicePage: {},
    ViewDevices:[]
}