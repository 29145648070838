export default {
    UserId: '',
    User: {},
    Users: [],
    TotalInfo: {
        totalRecord: 0,
        pageSize: 20,
        pageIndex: 1
    },
    UserPage: {},
}